import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as IconAcompanamiento } from '../../../../assets/asideMenu/icon_acompanamiento.svg';
import { ReactComponent as IconDashboard } from '../../../../assets/asideMenu/icon_dashboard.svg';
import { ReactComponent as IconNotifications } from '../../../../assets/asideMenu/icon_notifications.svg';
import { ReactComponent as IconOval } from '../../../../assets/asideMenu/icon_oval.svg';
import styles from '../Layout/layout.module.scss';

export interface MenuVecinoProps {
  isOpenMenu: boolean;
  canViewNotifications: boolean;
  canViewAcompanamiento: boolean;
  canReadDonacionOwn: boolean;
  canPublishComunicaciones: boolean;
  hasUnreadAlerts: boolean;
  isVecino: boolean;
  isAdmin: boolean;
  isOnlyVecino: boolean;
  styleRoute: (route: string) => string;
}

const MenuVecino: React.FC<MenuVecinoProps> = ({
  isOpenMenu,
  canViewNotifications,
  canViewAcompanamiento,
  canReadDonacionOwn,
  canPublishComunicaciones,
  hasUnreadAlerts,
  isVecino,
  isAdmin,
  isOnlyVecino,
  styleRoute,
}) => {
  const location = useLocation();
  const inicio = '/';
  const mis_donaciones = '/mis-donaciones';
  const acompanamiento = '/acompanamiento';
  const comunicacion_nueva = '/comunicacion/nueva';
  const notificaciones = '/notificaciones';
  const perfil = '/perfil';
  return (
    <ul className={styles.mainMenu}>
      {isOnlyVecino && (
        <li className={`${styleRoute(inicio)}`}>
          <Link
            data-testid="dashboardLink"
            title="Inicio"
            className={styles.menuLink}
            to={inicio}
          >
            <div className={styles.iconWrapper}>
              <IconDashboard className={styles.menuIcon} />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isDashboardVisible' : 'isDashboardHidden'
              }
              className={styles.menuText}
            >
              Inicio
            </span>
          </Link>
        </li>
      )}
      {canReadDonacionOwn && (
        <li className={`${styleRoute(mis_donaciones)}`}>
          <Link
            data-testid="misdonacionesLink"
            className={styles.menuLink}
            to={mis_donaciones}
            title="Mis Donaciones"
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'piggy-bank']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isMisDonacionesVisible' : 'isMisDonacionesHidden'
              }
              className={styles.menuText}
            >
              Mis Donaciones
            </span>
          </Link>
        </li>
      )}
      {canViewAcompanamiento && (
        <li className={`${styleRoute(acompanamiento)}`}>
          <Link
            data-testid="usersLink"
            className={styles.menuLink}
            to={acompanamiento}
            title="Acompañamiento"
          >
            <div className={styles.iconWrapper}>
              <IconAcompanamiento className={styles.menuIcon} />
            </div>
            <span
              data-testid={
                isOpenMenu
                  ? 'isAcompanamientoVisible'
                  : 'isAcompanamientoHidden'
              }
              className={styles.menuText}
            >
              Acompañamiento
            </span>
          </Link>
        </li>
      )}
      {false && canPublishComunicaciones && (
        <li className={`${styleRoute(comunicacion_nueva)}`}>
          <Link
            data-testid="comunicacionNuevaLink"
            className={styles.menuLink}
            to={comunicacion_nueva}
            title="Comunicar fallecimiento"
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'mail-bulk']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu
                  ? 'isComunicacionNuevaVisible'
                  : 'isComunicacionNuevaHidden'
              }
              className={styles.menuText}
            >
              Fallecimiento
            </span>
          </Link>
        </li>
      )}
      {canViewNotifications && (
        <li
          className={`${styleRoute(notificaciones)} ${styles.isNotification}`}
        >
          <Link
            data-testid="notificationsLink"
            className={styles.menuLink}
            to={notificaciones}
            title="Alertas"
          >
            <div
              className={`${styles.iconWrapper} ${
                hasUnreadAlerts ? styles.alarmAnimation : ''
              }`}
            >
              {hasUnreadAlerts && <IconOval className={styles.alarmOval} />}
              <IconNotifications className={styles.menuIcon} />
            </div>
            <span className={styles.menuText}>Alertas</span>
          </Link>
        </li>
      )}
      <li
        className={`${styleRoute(perfil)} ${
          !canViewNotifications ? styles.isNotification : ''
        }`}
      >
        <Link
          className={`${styles.menuLink}`}
          data-testid="perfilLink"
          to={
            isVecino
              ? perfil
              : isAdmin
                ? `${perfil}/seguridad`
                : `${perfil}/datos`
          }
          title="Mi Perfil"
        >
          <div className={styles.iconWrapper}>
            <FontAwesomeIcon
              icon={['fas', 'user-circle']}
              size="lg"
              className={styles.menuIconPerfil}
            />
          </div>
          <span className={styles.menuText}>Mi perfil</span>
        </Link>
      </li>
    </ul>
  );
};

export default MenuVecino;
