import { APIValidationError } from '../../types/api/api';
import { Sesion } from '../../types/sesiones/sesion';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export const listSesiones = async (
  solicitud_id: UUID,
): Promise<Result<Sesion[], APIValidationError>> => {
  return validatedApiCall<Sesion[]>(
    `/api/solicitudes/${solicitud_id}/sesiones`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
