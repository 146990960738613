import React from 'react';
import { connect } from 'react-redux';
import {
  hide,
  del as deleteEntidad,
} from '../../../store/entidades/deleteEntidadModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';
import {
  selectDeleteEntidad,
  selectDeleteEntidadActive,
  selectDeleteEntidadLoading,
} from '../../../store/entidades/selectors';
import { Entidad } from '../../../types/entidad/Entidad';

interface DeleteEntidadProps {
  entidad: Entidad | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteEntidad: (id: UUID) => void;
}

const DeleteEntidad: React.FC<DeleteEntidadProps> = ({
  entidad,
  active,
  loading,
  closeModal,
  deleteEntidad,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {entidad && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar entidad
            </h1>
            <p className={styles.description}>
              ¿Estás seguro de querer eliminar la entidad?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>Nombre:</span>
                  {entidad.nombre}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                variant="positive"
                onClick={() => {
                  deleteEntidad(entidad.id);
                  closeModal();
                }}
                data-testid="submit_button"
              >
                Eliminar
              </Button>
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteEntidad = connect(
  (state: RootState) => ({
    entidad: selectDeleteEntidad(state),
    active: selectDeleteEntidadActive(state),
    loading: selectDeleteEntidadLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteEntidad: (payload: UUID) => dispatch(deleteEntidad(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteEntidad);

export default renderWhen(ConnectedDeleteEntidad, selectDeleteEntidadActive);
