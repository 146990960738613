import { getVoluntarioDisponibilidad as callGetVoluntarioDisponibilidad } from '../../api/voluntarios/getVoluntarioDisponibilidad';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const voluntarioDisponibilidadModule = createApiModule({
  name: 'voluntarioDisponibilidad',
  apiMethod: callGetVoluntarioDisponibilidad,
  parse: (voluntarioDisponibilidad) => voluntarioDisponibilidad,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreVoluntariosVoluntarioDisponibilidadModuleNoHemosPodidoRecuperarTuEstadoDeDisponibilidad',
    ),
  }),
});

export const {
  start: getVoluntarioDisponibilidad,
  success: getVoluntarioDisponibilidadOk,
  error: getVoluntarioDisponibilidadKo,
  loading: getVoluntarioDisponibilidadLoading,
} = voluntarioDisponibilidadModule.slice.actions;

export default voluntarioDisponibilidadModule;
