import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  createContext,
} from 'react';
import styles from './popupMenu.module.scss';
import { ReactComponent as IconMore } from '../../../assets/more.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PopupMenuContext = createContext((x: boolean) => {});

interface PopupMenuProps {
  children: React.ReactNode;
}

const PopupMenu: React.FC<PopupMenuProps> = ({ children }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsOpenMenu(!isOpenMenu);
  }, [isOpenMenu]);

  const ref = useRef<HTMLHeadingElement>(null);

  const handleClickOutside = useCallback((event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpenMenu(false);
    }
  }, []);

  const handlePressEsc = useCallback((event: any) => {
    if (event.keyCode === 27) {
      setIsOpenMenu(false);
    }
  }, []);

  useEffect(() => {
    if (isOpenMenu) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handlePressEsc);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handlePressEsc);
      };
    }
  }, [isOpenMenu, handleClickOutside, handlePressEsc]);

  return (
    <div className={styles.root} ref={ref}>
      <button
        onClick={(e) => {
          toggleMenu();
          e.stopPropagation();
        }}
        className={styles.button}
      >
        <IconMore />
      </button>
      <PopupMenuContext.Provider value={setIsOpenMenu}>
        <ul
          data-testid={isOpenMenu ? 'popupIsOpen' : 'popupIsClose'}
          className={
            isOpenMenu ? `${styles.menu} ${styles.isOpen}` : styles.menu
          }
        >
          {children}
        </ul>
      </PopupMenuContext.Provider>
    </div>
  );
};

export default PopupMenu;
