import { EntidadesDTO, getEntidades } from '../../api/entidades/getEntidades';
import { GenericState } from '../../types/apiModule';
import { Entidad } from '../../types/entidad/Entidad';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';
import { t } from 'i18next';
export interface EntidadModule extends GenericState<unknown> {
  data: EntidadesDTO;
}

const initialState: EntidadModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Entidad[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listEntidadModule = createApiModule({
  name: 'listEntidades',
  apiMethod: getEntidades,
  initialState,
  parse: (entidades) => entidades,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreEntidadesListEntidadModuleErrorListandoLasEntidades'),
  }),
  onValidationError: () => {},
});

export const {
  start: listEntidades,
  success: listEntidadesOk,
  error: listEntidadesKo,
  refresh: listEntidadesRefresh,
  reset: listEntidadesReset,
} = listEntidadModule.slice.actions;

export const { sagas } = listEntidadModule;

export default listEntidadModule;
