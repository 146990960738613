import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Solicitud } from '../../types/solicitud/solicitud';
import { EstadoSolicitud } from '../../types/solicitud/estadoSolicitud';
import { UUID } from '../../types/standard';

export interface ChangeEstadoSolicitudDTO {
  estado: EstadoSolicitud;
  voluntario_id?: UUID;
  solicitud_id: UUID;
}

export const changeEstadoSolicitud = async (
  data: ChangeEstadoSolicitudDTO,
): Promise<Result<Solicitud, APIValidationError>> => {
  return validatedApiCall<Solicitud>(`/api/solicitudes/${data.solicitud_id}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
