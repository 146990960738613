import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));

const RecursosRoutes = [
  {
    path: 'recursos',
    element: (
      <PrivateRoute allowedPermissions={['read_resource']} component={List} />
    ),
  },
];

export default RecursosRoutes;
