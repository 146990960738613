import { Result, validatedApiCall } from '../api';
import { ISO8601DateString } from '../../types/standard';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { Donacion } from '../../types/donaciones/donaciones';
import { Kpis } from '../../types/facturacion/facturacion';
import { Month } from '../../types/facturacion/month';

export interface FacturacionDTO {
  kpis: Kpis[];
  donaciones: Donacion[];
}

export interface GetFacturacionDTO {
  pueblo_id?: string;
  month?: Month | '';
  date_from?: ISO8601DateString;
  date_to?: ISO8601DateString;
}

export const getFacturacion = async (
  data: GetFacturacionDTO,
): Promise<Result<FacturacionDTO, APIValidationError>> => {
  return validatedApiCall<FacturacionDTO>(
    `/api/facturacion?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
