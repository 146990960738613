import React from 'react';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { User } from '../../../types/users/users';

import {
  selectDeleteVecino,
  selectDeleteVecinoActive,
  selectDeleteVecinoLoading,
} from '../../../store/vecinos/selectors';
import {
  hide,
  del as deleteVecino,
} from '../../../store/vecinos/deleteVecinoModule';

interface DeleteVecinoProps {
  user: User | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteVecino: (id: UUID | undefined) => void;
}

const DeleteVecino: React.FC<DeleteVecinoProps> = ({
  user,
  active,
  loading,
  closeModal,
  deleteVecino,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {user && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar vecino/a
            </h1>
            <p className={styles.description}>
              ¿Estás seguro de querer eliminar el vecino/a?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>Nombre:</span>{' '}
                  {user.nombre} {user.apellido1} {user.apellido2}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              {user.id && (
                <Button
                  variant="positive"
                  onClick={() => {
                    deleteVecino(user.id);
                    closeModal();
                  }}
                  data-testid="submit_button"
                >
                  Eliminar
                </Button>
              )}
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteVecino = connect(
  (state: RootState) => ({
    user: selectDeleteVecino(state),
    active: selectDeleteVecinoActive(state),
    loading: selectDeleteVecinoLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteVecino: (payload: UUID | undefined) =>
      dispatch(deleteVecino(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteVecino);

export default renderWhen(ConnectedDeleteVecino, selectDeleteVecinoActive);
