import { listSesiones as callListSesiones } from '../../api/sesiones/listSesiones';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const listSesionModule = createApiModule({
  name: 'listSesiones',
  apiMethod: callListSesiones,
  parse: (sesiones) => sesiones,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreSesionListSesionModuleNoHemosPodidoCargarLasSesionesDelAcompanamiento',
    ),
  }),
  onValidationError: () => {},
});

export const {
  start: listSesiones,
  success: listSesionesOk,
  error: listSesionesKo,
} = listSesionModule.slice.actions;
export const { sagas } = listSesionModule;
export default listSesionModule;
