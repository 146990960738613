import { FieldError } from 'react-hook-form/dist/types';

export type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

export type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];

export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? {
        [K in keyof T]-?: K extends string | number
          ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
          : never;
      }[keyof T]
    : '';

export function getError(errors: any, path: string): FieldError | undefined {
  const parts = path.split('.');
  let current = errors;
  let result = undefined;
  parts.forEach((part) => {
    if (part in current) {
      current = current[part];
      result = current;
    } else {
      result = undefined;
    }
  });
  if (result) {
    return result as FieldError;
  }
  return undefined;
}
