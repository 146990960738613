import { updateVecino } from '../../api/vecinos/updateVecino';
import createApiModule from '../createApiModule';

const updateDatosPersonalesModule = createApiModule({
  name: 'updateDatosPersonales',
  apiMethod: updateVecino,
  onSuccess: (user) => ({
    message: 'Tus datos se ha modificado correctamente.',
    path: '/',
  }),
  onError: () => ({
    message: 'Error al modificar tus datos',
  }),
  onValidationError: () => ({
    message: 'Error al modficar tus datos',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateDatosPersonalesModule.slice.actions;

export default updateDatosPersonalesModule;
