import Stripe from 'stripe';
import { APIValidationError } from '../../../types/api/api';
import { UUID } from '../../../types/standard';
import { Result, validatedApiCall } from '../../api';

export interface DefaultPaymentMethodDTO {
  user_id: UUID;
  payment_method_id: string;
}

export const setDefaultPaymentMethod = async (
  dto: DefaultPaymentMethodDTO,
): Promise<Result<Stripe.Customer, APIValidationError>> => {
  return validatedApiCall<Stripe.Customer>(
    `/api/stripe/override/customers/${dto.user_id}/default_payment_method`,
    {
      method: 'POST',
      auth: false,
      body: JSON.stringify({ payment_method_id: dto.payment_method_id }),
    },
  );
};

export const getCustomer = async (): Promise<
  Result<Stripe.Customer, APIValidationError>
> => {
  return validatedApiCall<Stripe.Customer>(
    `/api/stripe/override_restricted/customers`,
    {
      method: 'get',
      auth: true,
    },
  );
};
