import { useTranslation } from 'react-i18next';
import React from 'react';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { User } from '../../../types/users/users';

import {
  deleteVoluntario,
  hide,
} from '../../../store/voluntarios/deleteVoluntarioModule';
import {
  selectDeleteVoluntarioActive,
  selectDeleteVoluntarioVecino,
} from '../../../store/voluntarios/selectors';

interface DeleteVoluntarioProps {
  user: User | null;
  active: boolean;
  closeModal: () => void;
  deleteVoluntario: (id: UUID | undefined) => void;
}

const DeleteVoluntario: React.FC<DeleteVoluntarioProps> = ({
  user,
  active,
  closeModal,
  deleteVoluntario,
}) => {
  const { t } = useTranslation();
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {user && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              {t(
                'ComponentsPopupActionsDeleteVoluntarioDeleteVoluntarioDarDeBajaDelVoluntariado',
              )}
            </h1>
            <p className={styles.description}>
              {t(
                'ComponentsPopupActionsDeleteVoluntarioDeleteVoluntarioEstasSeguroDeQuererDarDeBajaElLaVoluntarioA',
              )}
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>
                    {t(
                      'ComponentsPopupActionsDeleteVoluntarioDeleteVoluntarioNombre',
                    )}
                    :
                  </span>{' '}
                  {user.nombre} {user.apellido1} {user.apellido2}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                {t('CommonCancelar')}
              </Button>
              {user.id && (
                <Button
                  variant="positive"
                  onClick={() => {
                    deleteVoluntario(user.id);
                    closeModal();
                  }}
                  data-testid="submit_button"
                >
                  {t(
                    'ComponentsPopupActionsDeleteVoluntarioDeleteVoluntarioDarDeBajaDelVoluntariado',
                  )}
                </Button>
              )}
            </ButtonsGroup>
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteVoluntario = connect(
  (state: RootState) => ({
    user: selectDeleteVoluntarioVecino(state),
    active: selectDeleteVoluntarioActive(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteVoluntario: (payload: UUID | undefined) =>
      dispatch(deleteVoluntario(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteVoluntario);

export default renderWhen(
  ConnectedDeleteVoluntario,
  selectDeleteVoluntarioActive,
);
