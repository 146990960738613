import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { VoluntarioAcuerdo } from '../../types/voluntarios/VoluntarioAcuerdo';

export const postConfirmarVoluntarioAcuerdo = async (
  id: UUID,
): Promise<Result<VoluntarioAcuerdo, APIValidationError>> => {
  return validatedApiCall<VoluntarioAcuerdo>(
    `/api/voluntarios/${id}/acuerdo/confirmar`,
    {
      method: 'POST',
      auth: true,
      body: JSON.stringify({}),
    },
  );
};
