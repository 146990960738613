import { getKpis, KpisCollection } from '../../api/kpis/getKpis';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { t } from 'i18next';
export interface KpisModule extends GenericState<unknown> {
  data: KpisCollection;
}

const initialState: KpisModule = {
  errors: null,
  loading: false,
  data: {} as KpisCollection,
};

const kpisModule = createApiModule({
  name: 'listKpis',
  apiMethod: getKpis,
  initialState,
  parse: (kpis) => kpis,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreKpisKpisModuleErrorListandoLosIndicadores'),
  }),
  onValidationError: () => {},
});

export const {
  start: listKpis,
  success: listKpisOk,
  error: listKpisKo,
} = kpisModule.slice.actions;

export default kpisModule;
