import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { UpdateActividad } from '../../types/actividad/Actividad';

export const updateActividad = async (
  data: UpdateActividad,
): Promise<Result<boolean, APIValidationError>> => {
  return validatedApiCall<boolean>(`/api/actividad/${data.id}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
