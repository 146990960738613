import Stripe from 'stripe';
import { getPaymentMethods } from '../../../api/stripe/override/paymentMethods';
import { GenericState } from '../../../types/apiModule';
import createApiModule from '../../createApiModule';
import { t } from 'i18next';
export interface ListPaymentMethodsModule extends GenericState<unknown> {
  data: Stripe.PaymentMethod[];
}

const initialState: ListPaymentMethodsModule = {
  errors: null,
  loading: false,
  data: [],
};

const listPaymentMethodsModule = createApiModule({
  name: 'listPaymentMethods',
  apiMethod: getPaymentMethods,
  initialState,
  parse: (data) => data,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreStripeCustomerListPaymentMethodsErrorRecuperandoLosMetodosDePagoConfigurados',
    ),
  }),
  onValidationError: () => {},
});

export const {
  start: listPaymentMethods,
  success: listPaymentMethodsOk,
  error: listPaymentMethodsKo,
  reset: listPaymentMethodsReset,
} = listPaymentMethodsModule.slice.actions;

export const { sagas } = listPaymentMethodsModule;

export default listPaymentMethodsModule;
