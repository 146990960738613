import Stripe from 'stripe';
import { APIValidationError } from '../../../types/api/api';
import { UUID } from '../../../types/standard';
import { Result, validatedApiCall } from '../../api';

export const createSetupIntent = async (
  user_id: UUID,
): Promise<Result<Stripe.SetupIntent, APIValidationError>> => {
  return validatedApiCall<Stripe.SetupIntent>(
    `/api/stripe/override/setup_intents/${user_id}`,
    {
      method: 'POST',
      auth: false,
      body: JSON.stringify({}),
    },
  );
};
