import { registerValoracion } from '../../api/valoraciones/registerValoracion';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listSolicitudesRefresh } from '../solicitud/listSolicitudModule';
import { t } from 'i18next';

const registerValoracionModule = createApiModule({
  name: 'registerValoracion',
  apiMethod: registerValoracion,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_CORREO_EVALUACION_ENVIADO'),
    }),
    listSolicitudesRefresh(),
  ],
  onError: () => ({
    message: t(
      'StoreValoracionesRegisterValoracionModuleSeHaProducidoUnErrorEnviandoLaSolicitudDeEvaluacion',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreValoracionesRegisterValoracionModuleSeHaProducidoUnErrorEnviandoLaSolicitudDeEvaluacion',
    ),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerValoracionModule.slice.actions;

export default registerValoracionModule;
