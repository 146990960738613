import { APIValidationError } from '../../../types/api/api';
import { StripeProducts } from '../../../types/stripe';
import { Result, validatedApiCall } from '../../api';

export const getProducts = async (
  withPrices: boolean,
): Promise<Result<StripeProducts, APIValidationError>> => {
  const queryString = withPrices ? '?withPrices=true' : '';
  return validatedApiCall<StripeProducts>(
    `/api/stripe/bridge/products${queryString}`,
    {
      method: 'GET',
      auth: false,
    },
  );
};
