import { Result, validatedApiCall } from '../api';
import { SendPasswordDTO } from '../../types/forgotPassword/forgotPassword';
import { APIValidationError } from '../../types/api/api';

export const sendPassword = async (
  data: SendPasswordDTO,
): Promise<Result<boolean, APIValidationError>> => {
  return validatedApiCall<boolean>(`/api/auth/sendPassword`, {
    method: 'PUT',
    auth: false,
    body: JSON.stringify(data),
  });
};
