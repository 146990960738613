import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import registerIcons from './helpers/registerIcons';
import ImportOrderEnforcer from './ImportOrderEnforcer';
import Index from './routes';
import store, { persistor } from './store/store';
import { BrowserRouter } from 'react-router-dom';

// See the module to understand what this is
ImportOrderEnforcer();
registerIcons();

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Index />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
