import { PayloadAction } from '@reduxjs/toolkit';
import { cancelarSocio } from '../../api/vecinos/vecinoSocio';
import { GenericState } from '../../types/apiModule';
import { User } from '../../types/users/users';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listVecinosRefresh } from './listVecinosModule';
import { t } from 'i18next';

export interface cancelAssociateSubscriptionState
  extends GenericState<unknown> {
  active: boolean;
  user: User;
}
const initialState: cancelAssociateSubscriptionState = {
  errors: null,
  data: [],
  loading: false,
  active: false,
  user: {} as User,
};

const cancelAssociateSubscriptionModule = createApiModule({
  name: 'cancelAssociateSubscription',
  initialState,
  apiMethod: cancelarSocio,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_VECINO_NO_SOCIO'),
    }),
    listVecinosRefresh(),
  ],
  onError: () => ({
    message: t(
      'StoreVecinosCancelAssociateSubscriptionErrorAlCancelarLaSubscripcionDeSocioDelVecino',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreVecinosCancelAssociateSubscriptionErrorAlCancelarLaSubscripcionDeSocioDelVecino',
    ),
  }),
  reducers: {
    show(state, action: PayloadAction<User>) {
      state.active = true;
      state.user = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: cancelAssociateSubscription,
  success: cancelAssociateSubscriptionOk,
  error: cancelAssociateSubscriptionKo,
  hide,
  show,
} = cancelAssociateSubscriptionModule.slice.actions;

export default cancelAssociateSubscriptionModule;
