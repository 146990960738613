import { postConfirmarVoluntarioAcuerdo } from '../../api/voluntarios/postConfirmarVoluntarioAcuerdo';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const updateVoluntarioAcuerdoModule = createApiModule({
  name: 'updateVoluntarioAcuerdo',
  apiMethod: postConfirmarVoluntarioAcuerdo,
  parse: (voluntarioAcuerdo) => voluntarioAcuerdo,
  onSuccess: () => ({
    message: t('StoreVoluntariosVoluntarioAcuerdoConfirmado'),
  }),
  onError: () => ({
    message: t(
      'StoreVoluntariosUpdateVoluntarioAcuerdoModuleErrorAlConfirmarElAcuerdo',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreVoluntariosUpdateVoluntarioAcuerdoModuleErrorAlConfirmarElAcuerdo',
    ),
  }),
});

export const {
  start: updateVoluntarioAcuerdo,
  success: updateVoluntarioAcuerdoOk,
  error: updateVoluntarioAcuerdoKo,
} = updateVoluntarioAcuerdoModule.slice.actions;

export default updateVoluntarioAcuerdoModule;
