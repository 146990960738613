import { useTranslation } from 'react-i18next';
import React from 'react';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {
  cancelAssociateSubscription,
  hide as hideCancelAssociateSubscription,
} from '../../../store/vecinos/cancelAssociateSubscription';
import {
  selectCancelAssociateSubscriptionActive,
  selectCancelAssociateSubscriptionUser,
} from '../../../store/vecinos/selectors';
import { User } from '../../../types/users/users';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import Modal from '../../common/Modal/Modal';
import renderWhen from '../../../helpers/renderWhen';
import styles from '../changeActions.module.scss';

interface CancelAssociateSubscriptionProps {
  active: boolean;
  user: User | null;
  closeModal: () => void;
  deleteSocio: (x: UUID) => void;
}

const CancelAssociateSubscription: React.FC<
  CancelAssociateSubscriptionProps
> = ({ active, user, closeModal, deleteSocio }) => {
  const { t } = useTranslation();
  if (!active || !user) return null;

  return (
    <>
      <Modal closeModal={closeModal}>
        <div className={styles.container}>
          <h1 className={styles.title}>
            {t(
              'ComponentsPopupActionsCancelAssociateSubscriptionCancelAssociateSubscriptionBajaSocioA',
            )}
          </h1>
          <div className={styles.appointmentWrapper}>
            <div className={styles.description}>
              <p>
                {t(
                  'ComponentsPopupActionsCancelAssociateSubscriptionCancelAssociateSubscriptionDeseasContinuar',
                )}
              </p>
            </div>
          </div>
          <ButtonsGroup variant="center">
            <Button type="button" variant="negative" onClick={closeModal}>
              {t('CommonCancelar')}
            </Button>

            <Button
              variant="positive"
              onClick={() => {
                if (user.id) {
                  deleteSocio(user.id);
                  closeModal();
                }
              }}
              data-testid="submit_button"
            >
              {t(
                'ComponentsPopupActionsCancelAssociateSubscriptionCancelAssociateSubscriptionBajaAnular',
              )}
            </Button>
          </ButtonsGroup>
        </div>
      </Modal>
    </>
  );
};

const ConnectedCancelAssociateSubscription = connect(
  (state: RootState) => ({
    active: selectCancelAssociateSubscriptionActive(state),
    user: selectCancelAssociateSubscriptionUser(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteSocio: (payload: UUID) =>
      dispatch(cancelAssociateSubscription(payload)),
    closeModal: () => dispatch(hideCancelAssociateSubscription()),
  }),
)(CancelAssociateSubscription);

export default renderWhen(
  ConnectedCancelAssociateSubscription,
  selectCancelAssociateSubscriptionActive,
);
