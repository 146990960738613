import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { PostActividad as postActividadDto } from '../../types/actividad/post.d';

export const postActividad = async (
  data: postActividadDto,
): Promise<Result<void, APIValidationError>> => {
  return validatedApiCall<void>(`/api/actividad`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
