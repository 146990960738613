import { TipoEntidad } from '../../types/entidad/tipo_entidad';
import { RootState } from '../store';

/********************ENTIDAD **************************************/
export const selectEntidadSlice = (state: RootState) => state.entidad;

export const selectEntidad = (state: RootState) =>
  selectEntidadSlice(state).data;

export const selectEntidadErrors = (state: RootState) =>
  selectEntidadSlice(state).errors;

export const selectEntidadLoading = (state: RootState) =>
  selectEntidadSlice(state).loading;

/********************LIST ENTIDADES *********************************/
export const selectEntidadesSlice = (state: RootState) => state.entidades;

export const selectEntidades = (state: RootState) =>
  selectEntidadesSlice(state).data.data;

export const selectEntidadesByTipo = (state: RootState, tipo: TipoEntidad) =>
  selectEntidades(state).filter((entidad) => {
    return entidad.tipo === tipo;
  });

export const selectEntidadesByTipos = (
  state: RootState,
  tipos: TipoEntidad[],
) =>
  selectEntidades(state).filter((entidad) => {
    return tipos.includes(entidad.tipo);
  });

export const selectEntidadesPageInfo = (state: RootState) =>
  selectEntidadesSlice(state).data.meta;

export const selectEntidadesErrors = (state: RootState) =>
  selectEntidadesSlice(state).errors;

export const selectEntidadesLoading = (state: RootState) =>
  selectEntidadesSlice(state).loading;

/******************** REGISTER ENTIDAD *****************************/
export const selectRegisterEntidad = (state: RootState) =>
  state.registerEntidad;

export const selectRegisterEntidadLoading = (state: RootState) =>
  selectRegisterEntidad(state)?.loading;

export const selectRegisterEntidadErrors = (state: RootState) =>
  selectRegisterEntidad(state)?.errors;

/******************** DELETE ENTIDADES *****************************/
export const selectDeleteEntidad = (state: RootState) =>
  state.deleteEntidad.entidad;

export const selectDeleteEntidadActive = (state: RootState) =>
  state.deleteEntidad.active;

export const selectDeleteEntidadLoading = (state: RootState) =>
  state.deleteEntidad.loading;
