import { t } from 'i18next';

export type Role =
  | 'vecino'
  | 'responsable_entidad'
  | 'responsable_ayuntamiento'
  | 'agente_soporte'
  | 'admin';

export enum RoleEnum {
  'vecino' = 'Vecino',
  'responsable_entidad' = 'Responsable entidad',
  'responsable_ayuntamiento' = 'Responsable ayuntamiento',
  'agente_soporte' = 'Agente de apoyo',
  'admin' = 'Administrador',
}
