import { APIValidationError } from '../../types/api/api';
import { RegisterSesion, Sesion } from '../../types/sesiones/sesion';
import { Result, validatedApiCall } from '../api';

export const registerSesion = async (
  sesion: RegisterSesion,
): Promise<Result<Sesion, APIValidationError>> => {
  return validatedApiCall<Sesion>(
    `/api/solicitudes/${sesion.solicitud_id}/sesion`,
    {
      method: 'POST',
      auth: true,
      body: JSON.stringify(sesion),
    },
  );
};
