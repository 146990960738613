import { postConfirmarVoluntarioAcuerdo } from '../../api/voluntarios/postConfirmarVoluntarioAcuerdo';
import createApiModule from '../createApiModule';

const updateVoluntarioAcuerdoModule = createApiModule({
  name: 'updateVoluntarioAcuerdo',
  apiMethod: postConfirmarVoluntarioAcuerdo,
  parse: (voluntarioAcuerdo) => voluntarioAcuerdo,
  onSuccess: () => ({
    message: `Acuerdo confirmado correctamente`,
  }),
  onError: () => ({
    message: 'Error al confirmar el Acuerdo',
  }),
  onValidationError: () => ({
    message: 'Error al confirmar el Acuerdo',
  }),
});

export const {
  start: updateVoluntarioAcuerdo,
  success: updateVoluntarioAcuerdoOk,
  error: updateVoluntarioAcuerdoKo,
} = updateVoluntarioAcuerdoModule.slice.actions;

export default updateVoluntarioAcuerdoModule;
