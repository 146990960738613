import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { UpdatePuebloDTO } from '../../../api/pueblos/updatePueblo';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import renderWhen from '../../../helpers/renderWhen';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import {
  listEntidades,
  listEntidadesReset,
} from '../../../store/entidades/listEntidadModule';
import {
  selectEntidades,
  selectEntidadesLoading,
} from '../../../store/entidades/selectors';
import { getPueblo, resetPueblo } from '../../../store/pueblos/puebloModule';
import {
  selectPueblo,
  selectUpdatePuebloActive,
  selectUpdatePuebloErrors,
  selectUpdatePuebloLoading,
  selectUpdatePuebloPueblo,
} from '../../../store/pueblos/selectors';
import { hide, updatePueblo } from '../../../store/pueblos/updatePuebloModule';
import { AppDispatch, RootState } from '../../../store/store';
import { APIError } from '../../../types/api/api';
import { Entidad } from '../../../types/entidad/Entidad';
import { Pueblo } from '../../../types/pueblos/pueblo';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import GlobalError from '../../common/Form/GlobalError/GlobalError';
import ValidatedSelect from '../../common/Form/ValidatedSelect/ValidatedSelect';
import FormLayout from '../../common/Layouts/FormLayout/FormLayout';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';

const changeEntidadPrincipalSchema = yup
  .object()
  .shape({
    entidad: yup.object().shape({
      id: yup.string().required('Selecciona la nueva entidad principal'),
    }),
  })
  .defined();

interface ChangeEntidadPrincipalPuebloProps {
  pueblo: Pueblo;
  puebloWithEntidad: Pueblo | null;
  entidades: Entidad[];
  serverErrors: APIError | null;
  active: boolean;
  loading: boolean;
  loadingEntidades: boolean;
  loadPueblo: (x: UUID) => void;
  resetPueblo: () => void;
  closeModal: () => void;
  updatePueblo: (x: UpdatePuebloDTO) => void;
  loadEntidades: (pueblo_id: UUID) => void;
  resetEntidades: () => void;
}

const ChangeEntidadPrincipalPueblo: React.FC<
  ChangeEntidadPrincipalPuebloProps
> = ({
  pueblo,
  puebloWithEntidad,
  entidades,
  serverErrors,
  active,
  loading,
  loadingEntidades,
  loadPueblo,
  resetPueblo,
  closeModal,
  updatePueblo,
  loadEntidades,
  resetEntidades,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    formState,
  } = useForm<UpdatePuebloDTO>({
    mode: 'onSubmit',
    resolver: yupResolver(changeEntidadPrincipalSchema),
  });
  useEffect(() => {
    resetPueblo();
    resetEntidades();
  }, [resetPueblo, resetEntidades]);
  useEffect(() => {
    if (!puebloWithEntidad) {
      loadPueblo(pueblo.id);
      loadEntidades(pueblo.id);
    }
  }, [
    puebloWithEntidad,
    loadPueblo,
    loadEntidades,
    resetEntidades,
    pueblo,
    resetPueblo,
  ]);

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  // 'active' controls if the modal is open or close
  if (!active) return null;

  const changeEntidadPrincipalSubmit = (data: UpdatePuebloDTO) => {
    data.id = pueblo.id;
    updatePueblo(data);
    closeModal();
  };

  return (
    <>
      {pueblo && puebloWithEntidad && (
        <Modal variant="large" closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconReverse className={`${styles.icon} ${styles.isIconTrash}`} />
              Cambiar entidad principal
            </h1>
            <p className={styles.description}>
              {`La entidad principal de ${puebloWithEntidad.poblacion} es ${puebloWithEntidad.entidad_principal?.nombre}`}
            </p>
            <div className={styles.appointmentWrapper}>
              <FormLayout
                variant={'profile'}
                onSubmit={handleSubmit(changeEntidadPrincipalSubmit)}
              >
                <ValidatedSelect
                  schema={changeEntidadPrincipalSchema}
                  errors={errors}
                  label="Nueva entidad principal"
                  placeholder="Selecciona una entidad"
                  select={{
                    ...register('entidad.id'),
                    name: 'entidad.id',
                  }}
                >
                  {!loadingEntidades &&
                    entidades.map((entidad) => (
                      <React.Fragment key={entidad.id}>
                        {entidad.id !==
                          puebloWithEntidad.entidad_principal?.id && (
                          <option key={entidad.id} value={entidad.id}>
                            {entidad.nombre}
                          </option>
                        )}
                      </React.Fragment>
                    ))}
                </ValidatedSelect>
                <ButtonsGroup variant="profile">
                  <React.Fragment>
                    <Button
                      type="button"
                      variant="negative"
                      onClick={closeModal}
                    >
                      Cerrar
                    </Button>
                    <Button variant="positive">Guardar</Button>
                  </React.Fragment>
                </ButtonsGroup>
              </FormLayout>
            </div>
            {/* Server-side global error */}
            {globalError && <GlobalError message={globalError} />}

            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedChangeEntidadPrincipalPueblo = connect(
  (state: RootState) => ({
    pueblo: selectUpdatePuebloPueblo(state),
    puebloWithEntidad: selectPueblo(state),
    active: selectUpdatePuebloActive(state),
    loading: selectUpdatePuebloLoading(state),
    serverErrors: selectUpdatePuebloErrors(state),
    entidades: selectEntidades(state),
    loadingEntidades: selectEntidadesLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    loadPueblo: (payload: UUID) => dispatch(getPueblo(payload)),
    resetPueblo: () => dispatch(resetPueblo()),
    updatePueblo: (payload: UpdatePuebloDTO) => dispatch(updatePueblo(payload)),
    closeModal: () => dispatch(hide()),
    loadEntidades: (pueblo_id: UUID) =>
      dispatch(listEntidades({ pueblo: pueblo_id, size: 999 })),
    resetEntidades: () => dispatch(listEntidadesReset()),
  }),
)(ChangeEntidadPrincipalPueblo);

export default renderWhen(
  ConnectedChangeEntidadPrincipalPueblo,
  selectUpdatePuebloActive,
);
