import { registerComunicacion } from '../../api/comunicacion/registerComunicacion';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const registerComunicacionModule = createApiModule({
  name: 'registerComunicacion',
  apiMethod: registerComunicacion,
  onSuccess: (solicitud) => ({
    message: t('MESSAGE_SOLICITUD_ENVIADA'),
    path: '/',
  }),
  onError: () => ({
    message: t('StoreSolicitudSendSolicitudModuleErrorEnviandoLaSolicitud'),
  }),
  onValidationError: () => ({
    message: t('StoreSolicitudSendSolicitudModuleErrorEnviandoLaSolicitud'),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerComunicacionModule.slice.actions;

export default registerComunicacionModule;
