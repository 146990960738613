import { PayloadAction } from '@reduxjs/toolkit';
import { deleteActividad } from '../../api/actividad/deleteActividad';
import { Actividad } from '../../types/actividad/Actividad';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listActividadRefresh } from './listActividadModule';
import { t } from 'i18next';
export interface deleteActividadState extends GenericState<unknown> {
  active: boolean;
  data: Actividad[];
  actividad: Actividad | null;
}
const initialState: deleteActividadState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  actividad: null,
};

const deleteActividadModule = createApiModule({
  name: 'deleteActividad',
  initialState,
  apiMethod: deleteActividad,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_ACTIVIDAD_ELIMINADA'),
    }),
    listActividadRefresh(),
  ],
  onError: () => ({
    message: t('StoreActividadDeleteActividadModuleErrorBorrandoLaActividad'),
  }),
  onValidationError: () => ({
    message: t('StoreActividadDeleteActividadModuleErrorBorrandoLaActividad'),
  }),
  reducers: {
    show(state, action: PayloadAction<Actividad>) {
      state.active = true;
      state.actividad = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deleteActividadModule.slice.actions;

export default deleteActividadModule;
