/* eslint-disable no-console */
import { all, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { isApiError, setToken } from '../../api/api';
import {
  addState,
  login,
  logout,
  updateAvatar,
  updatePassword,
  updateProfile,
} from '../../api/auth/auth';
import { Await } from '../../types/api/api';
import {
  ChangeAvatarDTO,
  ChangePasswordDTO,
  LoginDTO,
} from '../../types/auth/auth';
import { UserStates } from '../../types/users/state';
import { User } from '../../types/users/users';
import snackBarSlice from '../snackBar/snackBarSlice';
import authSlice from './authSlice';
import restrictionsSagas from './restrictions/sagas';
import { t } from 'i18next';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loginSaga(
  action: PayloadAction<LoginDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(login, {
      email: action.payload.email,
      password: action.payload.password,
    })) as Await<ReturnType<typeof login>>;
    switch (result.type) {
      case 'ok':
        setToken(result.value.token.plainTextToken);
        yield put(authSlice.actions.loginOk(result.value));
        return;
      case 'validation-error':
        yield put(authSlice.actions.loginKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(authSlice.actions.loginKo(e));
    }
    throw e;
  }
}

function* logoutSaga() {
  // This is only to delete the token from the server
  // ... if fails, well... so be it.
  try {
    yield call(logout);
  } catch (e) {
    console.log(e);
  } finally {
    setToken(null);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* editProfile(action: PayloadAction<User>): Generator<any, void, any> {
  try {
    const result = (yield call(updateProfile, action.payload)) as Await<
      ReturnType<typeof updateProfile>
    >;
    switch (result.type) {
      case 'ok':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: t('MESSAGE_PERFIL_EDITADO'),
            path: '/perfil',
            severity: 'success',
          }),
        );
        yield put(authSlice.actions.editProfileOk(result.value));
        return;
      case 'validation-error':
        yield put(authSlice.actions.editProfileKo(result.value));
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(authSlice.actions.editProfileKo(e));
      yield put(
        snackBarSlice.actions.showSnackBar({
          message: t('MESSAGE_ERROR_ACTUALIZAR_PERFIL'),
          severity: 'error',
        }),
      );
    }
    throw e;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* changePasswordSaga(
  action: PayloadAction<ChangePasswordDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(updatePassword, action.payload)) as Await<
      ReturnType<typeof updatePassword>
    >;

    switch (result.type) {
      case 'ok':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: t('MESSAGE_CONTRASENA_ACTUALIZADA'),
            path: '/perfil',
            severity: 'success',
          }),
        );
        yield put(authSlice.actions.changePasswordOk());
        return;
      case 'validation-error':
        yield put(authSlice.actions.changePasswordKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield all([
        put(authSlice.actions.changePasswordKo(e)),
        put(
          snackBarSlice.actions.showSnackBar({
            message: t('MESSAGE_ERROR_ACTUALIZAR_CONTRASENA'),
            severity: 'error',
          }),
        ),
      ]);
    }
    throw e;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* changeAvatar(
  action: PayloadAction<ChangeAvatarDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(updateAvatar, action.payload)) as Await<
      ReturnType<typeof updateProfile>
    >;
    switch (result.type) {
      case 'ok':
        yield all([
          put(authSlice.actions.changeAvatarOk(result.value)),
          put(
            snackBarSlice.actions.showSnackBar({
              message: t('MESSAGE_AVATAR_ACTUALIZADO'),
              severity: 'success',
            }),
          ),
        ]);
        return;
      case 'validation-error':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: t('MESSAGE_ERROR_ACTUALIZAR_AVATAR'),
            severity: 'error',
          }),
        );
        yield put(authSlice.actions.changeAvatarKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield all([
        put(authSlice.actions.changeAvatarKo(e)),
        put(
          snackBarSlice.actions.showSnackBar({
            message: t('MESSAGE_ERROR_ACTUALIZAR_AVATAR'),
            severity: 'error',
          }),
        ),
      ]);
    }
    throw e;
  }
}

function* addUserState(
  action: PayloadAction<UserStates>,
): Generator<any, void, any> {
  try {
    const result = (yield call(addState, action.payload)) as Await<
      ReturnType<typeof addState>
    >;
    switch (result.type) {
      case 'ok':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: t('MESSAGE_DATOS_REGISTRADOS.'),
            path: '/',
            severity: 'success',
          }),
        );
        yield put(authSlice.actions.addUserStateOk(result.value));
        return;
      case 'validation-error':
        yield put(authSlice.actions.addUserStateKo(result.value));
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: t('MESSAGE_ERROR_GUARDANDO_CAMBIO'),
            severity: 'error',
          }),
        );
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(authSlice.actions.addUserStateKo(e));
      yield put(
        snackBarSlice.actions.showSnackBar({
          message: t('MESSAGE_ERROR_GUARDANDO_CAMBIO'),
          severity: 'error',
        }),
      );
    }
    throw e;
  }
}

const sagas = [
  takeLatest<PayloadAction<LoginDTO>>(authSlice.actions.login.type, loginSaga),
  takeEvery<PayloadAction<never>>(authSlice.actions.logout.type, logoutSaga),
  takeEvery<PayloadAction<User>>(
    authSlice.actions.editProfile.type,
    editProfile,
  ),
  takeEvery<PayloadAction<ChangePasswordDTO>>(
    authSlice.actions.changePassword.type,
    changePasswordSaga,
  ),
  takeEvery<PayloadAction<ChangeAvatarDTO>>(
    authSlice.actions.changeAvatar.type,
    changeAvatar,
  ),
  takeEvery<PayloadAction<UserStates>>(
    authSlice.actions.addUserState.type,
    addUserState,
  ),
  ...restrictionsSagas,
];

export default sagas;
