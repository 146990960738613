import { getVoluntarios } from '../../api/voluntarios/getVoluntarios';
import { Voluntario } from '../../types/voluntarios/Voluntario';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const initialState = {
  errors: null,
  loading: false,
  data: [] as Voluntario[],
};

const listVoluntariosModule = createApiModule({
  name: 'listVoluntarios',
  apiMethod: getVoluntarios,
  initialState,
  parse: (voluntarios) => voluntarios,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreVoluntariosListVoluntariosModuleErrorListandoLosVoluntarios',
    ),
  }),
  onValidationError: () => {},
});

export const {
  start: listVoluntarios,
  success: listVoluntariosOk,
  error: listVoluntariosKo,
  refresh: listVoluntariosRefresh,
  reset: listVoluntariosReset,
} = listVoluntariosModule.slice.actions;

export const { sagas } = listVoluntariosModule;

export default listVoluntariosModule;
