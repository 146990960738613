import { PayloadAction } from '@reduxjs/toolkit';
import { deleteEntidad } from '../../api/entidades/deleteEntidad';
import { GenericState } from '../../types/apiModule';
import { Entidad } from '../../types/entidad/Entidad';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listEntidadesRefresh } from './listEntidadModule';
import { t } from 'i18next';
export interface deleteEntidadesState extends GenericState<unknown> {
  active: boolean;
  data: Entidad[];
  entidad: Entidad | null;
}
const initialState: deleteEntidadesState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  entidad: null,
};

const deleteEntidadModule = createApiModule({
  name: 'deleteEntidad',
  initialState,
  apiMethod: deleteEntidad,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_ENTIDAD_ELIMINADA'),
    }),
    listEntidadesRefresh(),
  ],
  onError: () => ({
    message: t('StorePueblosDeletePuebloModuleErrorBorrandoElMunicipio'),
  }),
  onValidationError: (error) => ({
    message: error ? error.errors[0][0] : t('MESSAGE_ENTIDAD_ELIMINADA'),
  }),
  reducers: {
    show(state, action: PayloadAction<Entidad>) {
      state.active = true;
      state.entidad = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deleteEntidadModule.slice.actions;

export default deleteEntidadModule;
