import { postVoluntarioDisponibilidad } from '../../api/voluntarios/postVoluntarioDisponibilidad';
import createApiModule from '../createApiModule';

const updateVoluntarioDisponibilidadModule = createApiModule({
  name: 'updateVoluntarioDisponibilidad',
  apiMethod: postVoluntarioDisponibilidad,
  onSuccess: () => ({
    message: `Calendario de Disponibilidad guardado correctamente`,
  }),
  onError: () => ({
    message: 'Error al guardar el calendario de Disponibilidad',
  }),
  onValidationError: () => ({
    message: 'Error al guardar el calendario de Disponibilidad',
  }),
});

export const {
  start: updateVoluntarioDisponibilidad,
  success: updateVoluntarioDisponibilidadOk,
  error: updateVoluntarioDisponibilidadKo,
} = updateVoluntarioDisponibilidadModule.slice.actions;

export default updateVoluntarioDisponibilidadModule;
