import { bajaVecino as callBajaVecino } from '../../../api/users/bajaVecino';
import { logout } from '../../auth/authSlice';
import createApiModule from '../../createApiModule';
import { showSnackBar } from '../../snackBar/snackBarSlice';
import { t } from 'i18next';

const bajaVecinoModule = createApiModule({
  name: 'bajaVecino',
  apiMethod: callBajaVecino,
  onError: () => ({
    message: t(
      'StoreUsersVecinosBajaVecinoModuleSeHaProducidoUnErrorProcesandoSuBaja',
    ),
  }),
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_ELIMINADO_PLATAFORMA'),
    }),
    logout(),
  ],
});

export const {
  start: bajaVecino,
  success: bajaVecinoOk,
  error: bajaVecinoKo,
} = bajaVecinoModule.slice.actions;

export default bajaVecinoModule;
