import { APIValidationError } from '../../types/api/api';
import { PerfilVoluntario } from '../../types/voluntarios/PerfilVoluntario';
import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';

export const getPerfilVoluntario = async (
  user_id: UUID,
): Promise<Result<PerfilVoluntario, APIValidationError>> => {
  return validatedApiCall<PerfilVoluntario>(
    `/api/voluntarios/perfil/${user_id}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
