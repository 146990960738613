import React from 'react';
import { connect } from 'react-redux';
import { hide, del as deleteUser } from '../../../store/users/deleteUserModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { User } from '../../../types/users/users';
import {
  selectDeleteUser,
  selectDeleteUserActive,
  selectDeleteUserLoading,
} from '../../../store/users/selectors';

interface DeleteUserProps {
  user: User | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteUser: (id: UUID | undefined) => void;
}

const DeleteUser: React.FC<DeleteUserProps> = ({
  user,
  active,
  loading,
  closeModal,
  deleteUser,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {user && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar usuario/a
            </h1>
            <p className={styles.description}>
              ¿Estás seguro de querer eliminar el/la usuario/a?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>Nombre:</span>{' '}
                  {user.nombre} {user.apellido1} {user.apellido2}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              {user.id && (
                <Button
                  variant="positive"
                  onClick={() => {
                    deleteUser(user.id);
                    closeModal();
                  }}
                  data-testid="submit_button"
                >
                  Eliminar
                </Button>
              )}
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteUser = connect(
  (state: RootState) => ({
    user: selectDeleteUser(state),
    active: selectDeleteUserActive(state),
    loading: selectDeleteUserLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteUser: (payload: UUID | undefined) => dispatch(deleteUser(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteUser);

export default renderWhen(ConnectedDeleteUser, selectDeleteUserActive);
