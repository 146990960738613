import { APIValidationError } from '../../types/api/api';
import { Pueblo } from '../../types/pueblos/pueblo';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export interface UpdatePuebloDTO {
  id: UUID;
  entidad?: {
    id: UUID;
  };
}

export const updatePueblo = async (
  data: UpdatePuebloDTO,
): Promise<Result<Pueblo, APIValidationError>> => {
  return validatedApiCall<Pueblo>(`/api/pueblos/${data.id}`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
};
