import { getVoluntarioDisponibilidad as callGetVoluntarioDisponibilidad } from '../../api/voluntarios/getVoluntarioDisponibilidad';
import createApiModule from '../createApiModule';

const voluntarioDisponibilidadModule = createApiModule({
  name: 'voluntarioDisponibilidad',
  apiMethod: callGetVoluntarioDisponibilidad,
  parse: (voluntarioDisponibilidad) => voluntarioDisponibilidad,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido recuperar tu estado de disponibilidad',
  }),
});

export const {
  start: getVoluntarioDisponibilidad,
  success: getVoluntarioDisponibilidadOk,
  error: getVoluntarioDisponibilidadKo,
  loading: getVoluntarioDisponibilidadLoading,
} = voluntarioDisponibilidadModule.slice.actions;

export default voluntarioDisponibilidadModule;
