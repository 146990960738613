import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as IconError } from '../../../assets/snackBar/error.svg';
import { ReactComponent as IconNeutral } from '../../../assets/snackBar/neutral.svg';
import { ReactComponent as IconSuccess } from '../../../assets/snackBar/success.svg';
import { selectSnackBar } from '../../../store/snackBar/selectors';
import {
  hideSnackBar,
  SnackBarState,
} from '../../../store/snackBar/snackBarSlice';
import { AppDispatch, RootState } from '../../../store/store';
import styles from './snackBar.module.scss';
import { useNavigate } from 'react-router-dom';

export type Severity = 'error' | 'neutral' | 'success';

interface SnackBarProps extends SnackBarState {
  hideSnackBar: () => void;
}

const getSeverity = (severity: Severity): string => {
  switch (severity) {
    case 'error':
      return styles.isError;
    case 'success':
      return styles.isSuccess;
    default:
      return '';
  }
};

const SnackBar: React.FC<SnackBarProps> = ({
  isOpen,
  message,
  path,
  severity = 'neutral',
  autoHideDuration = 3200,
  hideSnackBar,
  id,
}) => {
  const navigateTo = useNavigate();

  // Only redirect when opening or rendering another message
  useEffect(() => {
    if (isOpen) {
      path && navigateTo(path);
    }
  }, [isOpen, navigateTo, path, id]);

  // But reset timer when anything else changes
  useEffect(() => {
    if (isOpen && autoHideDuration > 0) {
      const timer = setTimeout(() => {
        hideSnackBar();
      }, autoHideDuration);
      return () => clearTimeout(timer);
    }
  });

  const richContent = typeof message !== 'string';

  return (
    <div
      className={`${styles.snackBar} ${getSeverity(severity)} ${
        richContent ? styles.isRich : ''
      } ${isOpen ? styles.isOpen : ''}`}
    >
      {severity === 'success' && <IconSuccess />}
      {severity === 'error' && <IconError />}
      {severity === 'neutral' && <IconNeutral />}
      <p className={styles.text}>{message}</p>
    </div>
  );
};

export default connect(
  (state: RootState) => selectSnackBar(state),
  (dispatch: AppDispatch) => ({
    hideSnackBar: () => dispatch(hideSnackBar()),
  }),
)(SnackBar);
