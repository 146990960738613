import { postVoluntarioDisponibilidad } from '../../api/voluntarios/postVoluntarioDisponibilidad';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const updateVoluntarioDisponibilidadModule = createApiModule({
  name: 'updateVoluntarioDisponibilidad',
  apiMethod: postVoluntarioDisponibilidad,
  onSuccess: () => ({
    message: t('StoreVoluntariosVoluntarioCalendarioDisponibilidad'),
  }),
  onError: () => ({
    message: t(
      'StoreVoluntariosUpdateVoluntarioDisponibilidadModuleErrorAlGuardarElCalendarioDeDisponibilidad',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreVoluntariosUpdateVoluntarioDisponibilidadModuleErrorAlGuardarElCalendarioDeDisponibilidad',
    ),
  }),
});

export const {
  start: updateVoluntarioDisponibilidad,
  success: updateVoluntarioDisponibilidadOk,
  error: updateVoluntarioDisponibilidadKo,
} = updateVoluntarioDisponibilidadModule.slice.actions;

export default updateVoluntarioDisponibilidadModule;
