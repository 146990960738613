import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { registerSocio, SocioDTO } from '../../api/vecinos/vecinoSocio';
import { Await } from '../../types/api/api';
import snackBarSlice from '../snackBar/snackBarSlice';
import getVecinoSocioModule from './getVecinoSocioModule';
import registerVecinoSocioSlice from './registerVecinoSocioSlice';
import { isApiError } from '../../api/api';
import { t } from 'i18next';

function* registerVecinoSocio(
  action: PayloadAction<SocioDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(registerSocio, action.payload)) as Await<
      ReturnType<typeof registerSocio>
    >;
    switch (result.type) {
      case 'ok':
        yield put(registerVecinoSocioSlice.actions.registerOk(result.value));
        if (result.value.status !== 'active') {
          yield put(
            registerVecinoSocioSlice.actions.registerKo({
              errorType: 'unprocessable',
              message: t('MESSAGE_ERROR_SUSCRIPCION'),
            }),
          );
          //TODO analizar posibles errores reales
          // if (result.value.payment_intent) {
          //   console.log({ falla_payment_intent: result.value.payment_intent });
          // } else if (result.value.subscription) {
          //   console.log({ falla_subscription: result.value.payment_intent });
          // }
          yield put(
            snackBarSlice.actions.showSnackBar({
              severity: 'error',
              message: t('MESSAGE_FAIL_SUSCRIPCION'),
            }),
          );
        } else {
          yield put(
            snackBarSlice.actions.showSnackBar({
              severity: 'success',
              message: t('MESSAGE_SOCIOS_SUCCESS'),
              autoHideDuration: 8000,
            }),
          );
        }
        yield put(getVecinoSocioModule.slice.actions.start({}));
        return;
      case 'validation-error':
        yield put(registerVecinoSocioSlice.actions.registerKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(registerVecinoSocioSlice.actions.registerKo(e));
    }
    throw e;
  }
}

// async function confirmCard(
//   setupIntent: Stripe.SetupIntent
// ): Promise<SetupIntentResult> {
//   const stripe = await getStripe();
//   if (stripe && setupIntent.client_secret) {
//     return await stripe.confirmCardSetup(setupIntent.client_secret);
//   }
//   return {
//     error: {
//       type: 'api_connection_error',
//       message: 'Stripe no está disponible en estos momentos',
//     },
//   };
// }

// async function confirmSepaDebit(
//   setupIntent: Stripe.SetupIntent
// ): Promise<SetupIntentResult> {
//   const stripe = await getStripe();
//   if (stripe && setupIntent.client_secret) {
//     return stripe.confirmSepaDebitSetup(setupIntent.client_secret);
//   }
//   return {
//     error: {
//       type: 'api_connection_error',
//       message: 'Stripe no está disponible en estos momentos',
//     },
//   };
// }
const sagas = [
  takeLatest<PayloadAction<never>>(
    registerVecinoSocioSlice.actions.register.type,
    registerVecinoSocio,
  ),
];

export default sagas;
