import { APIValidationError } from '../../types/api/api';
import { Valoracion } from '../../types/valoracion/valoracion';
import { Result, validatedApiCall } from '../api';

export const updateValoracion = async (
  valoracion: Valoracion,
): Promise<Result<Valoracion, APIValidationError>> => {
  return validatedApiCall<Valoracion>(
    `/api/solicitudes/${valoracion.id}/valoracion`,
    {
      method: 'PATCH',
      auth: false,
      body: JSON.stringify(valoracion),
    },
  );
};
