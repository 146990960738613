import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { UUID } from '../../types/standard';
import { UserStates } from '../../types/users/state';
import { Vecino } from '../../types/users/users';

export interface GetVecinosDTO {
  page?: number;
  size?: number;
  buscar?: string | '';
  pueblo?: UUID | '';
  estado?: UserStates | '';
}

export interface VecinosDTO {
  data: Vecino[];
  links: LinksPage;
  meta: PageInfo;
}

export const getVecinos = async (
  data: GetVecinosDTO,
): Promise<Result<VecinosDTO, APIValidationError>> => {
  return validatedApiCall<VecinosDTO>(
    `/api/vecinos?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
