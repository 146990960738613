import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { TipoSolicitud } from '../../types/solicitud/tipoSolicitud';
import { SubTipoSolicitud } from '../../types/solicitud/subTipoSolicitud';
import { Solicitud } from '../../types/solicitud/solicitud';
import { TargetSolicitud } from '../../types/solicitud/targetSolicitud.d';
import {
  Email,
  ISO8601DateString,
  Phone,
  PostalCode,
  SpanishId,
  UUID,
} from '../../types/standard';
import { Provincia } from '../../types/provincia/provincia';

export interface RegisterSolicitudDTO {
  tipo: TipoSolicitud;
  subtipo: SubTipoSolicitud;
  motivo: string;
  target: TargetSolicitud;
  provincia: Provincia;
  beneficiario_id?: UUID | null;
  consentimiento_beneficiario: boolean;
  beneficiario_externo?: RegisterExternalUser | null;
}

export interface RegisterExternalUser {
  nombre: string;
  apellido1: string;
  apellido2: string;
  email: Email;
  telefono: Phone;
  genero: string;
  dni: SpanishId;
  fecha_nacimiento: ISO8601DateString;
  calle: string;
  numero: string;
  piso: string;
  codigo_postal: PostalCode;
  codigo_postal_id: number;
}

export const registerSolicitud = async (
  data: RegisterSolicitudDTO,
): Promise<Result<Solicitud, APIValidationError>> => {
  return validatedApiCall<Solicitud>(`/api/solicitudes`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
