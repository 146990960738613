import { updateUser } from '../../api/users/updateUser';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const updateUserModule = createApiModule({
  name: 'updateUser',
  apiMethod: updateUser,
  onSuccess: (user) => ({
    message: t('StoreUsuarioModificadoCorrectamente', {
      nombre: user.nombre,
      apellido1: user.apellido1,
      apellido2: user.apellido2,
    }),
    path: '/usuarios',
  }),
  onError: () => ({
    message: t('StoreUsersUpdateUserModuleErrorAlModificarElLaUsuarioA'),
  }),
  onValidationError: () => ({
    message: 'Error al modficar el/la usuario/a',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateUserModule.slice.actions;

export default updateUserModule;
