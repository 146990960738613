import { call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { CreatePaymentMethodData, StripeError } from '@stripe/stripe-js';
import { Stripe } from 'stripe';
import { detachPaymentMethod } from '../../../api/stripe/override/paymentMethods';
import { APIError, Await } from '../../../types/api/api';
import getStripe from '../../../utils/get-stripe';
import customerSlice from '../customer/customerSlice';
import { listPaymentMethods } from '../customer/listPaymentMethods';
import paymentMethodSlice from './paymentMethodSlice';

function* createPaymentMethod(
  action: PayloadAction<CreatePaymentMethodData>,
): Generator<any, void, any> {
  try {
    const stripe = yield call(getStripe);
    const { error, paymentMethod } = yield call(
      stripe.createPaymentMethod,
      action.payload,
    );
    if (error) {
      yield put(paymentMethodSlice.actions.createPaymentMethodKo(error));
      return;
    }
    yield put(paymentMethodSlice.actions.createPaymentMethodOk(paymentMethod));
    //reset stripe customer, now maybe doesn't have default payment method
    yield put(customerSlice.actions.reset());
    return;
  } catch (e) {
    const stripeError: StripeError = e as StripeError;
    yield put(paymentMethodSlice.actions.createPaymentMethodKo(stripeError));
  }
}

function* deletePaymentMethod(
  action: PayloadAction<Stripe.PaymentMethod>,
): Generator<any, void, any> {
  try {
    const result = (yield call(detachPaymentMethod, action.payload)) as Await<
      ReturnType<typeof detachPaymentMethod>
    >;
    switch (result.type) {
      case 'ok':
        //reset stripe customer, now maybe doesn't have default payment method
        yield put(customerSlice.actions.reset());
        yield put(paymentMethodSlice.actions.deletePaymentMethodOk());
        yield put(listPaymentMethods({}));
        return;
      case 'validation-error':
        yield put(
          paymentMethodSlice.actions.deletePaymentMethodKo(result.value),
        );
    }
  } catch (e) {
    const apiError: APIError = e as APIError;
    yield put(paymentMethodSlice.actions.deletePaymentMethodKo(apiError));
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    paymentMethodSlice.actions.createPaymentMethod.type,
    createPaymentMethod,
  ),
  takeLatest<PayloadAction<never>>(
    paymentMethodSlice.actions.deletePaymentMethod.type,
    deletePaymentMethod,
  ),
];

export default sagas;
