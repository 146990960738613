import {
  ComunicacionDTO,
  getComunicaciones,
} from '../../api/comunicacion/getComunicaciones';
import { GenericState } from '../../types/apiModule';
import { Comunicacion } from '../../types/comunicacion/comunicacion';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

export interface ComunicacionModule extends GenericState<unknown> {
  data: ComunicacionDTO;
}

const initialState: ComunicacionModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Comunicacion[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listComunicacionModule = createApiModule({
  name: 'listComunicacion',
  apiMethod: getComunicaciones,
  initialState,
  parse: (solicitud) => solicitud,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreSolicitudListSolicitudModuleErrorListandoLasSolicitudes'),
  }),
  onValidationError: () => {},
});

export const {
  start: listComunicacion,
  success: listComunicacionOk,
  error: listComunicacionKo,
  refresh: listComunicacionRefresh,
  reset: listComunicacionReset,
} = listComunicacionModule.slice.actions;

export default listComunicacionModule;
