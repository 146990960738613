import { registerEntidad } from '../../api/entidades/registerEntidad';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const registerEntidadModule = createApiModule({
  name: 'registerEntidad',
  apiMethod: registerEntidad,
  onSuccess: (entidad) => ({
    message: t('StoreEntidadCreadaCorrectamente', {
      nombre: entidad.nombre,
    }),
    path: '/entidades',
  }),
  onError: () => ({
    message: t('StoreEntidadesRegisterEntidadModuleErrorCreandoLaEntidad'),
  }),
  onValidationError: () => ({
    message: t('StoreEntidadesRegisterEntidadModuleErrorCreandoLaEntidad'),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerEntidadModule.slice.actions;

export const { sagas } = registerEntidadModule;

export default registerEntidadModule;
