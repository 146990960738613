import { RootState } from '../store';

/******************** VOLUNTARIOS **************************************/
export const selectVoluntariosSlice = (state: RootState) => state.voluntarios;
export const selectVoluntarios = (state: RootState) =>
  selectVoluntariosSlice(state).data;
export const selectVoluntariosLoading = (state: RootState) =>
  selectVoluntariosSlice(state).loading;

/**                      VoluntarioEstado                            */
export const selectVoluntarioEstado = (state: RootState) =>
  state.voluntarioEstado.data;
export const selectVoluntarioEstadoLoading = (state: RootState) =>
  state.voluntarioEstado.loading;

/**                      VoluntarioEstado                            */
export const selectBajaVoluntarioLoading = (state: RootState) =>
  state.voluntarioBaja.loading;
/******************** BAJA VOLUNTARIOS **************************************/
export const selectDeleteVoluntarioSlice = (state: RootState) =>
  state.voluntarioDelete;
export const selectDeleteVoluntarioVecino = (state: RootState) =>
  selectDeleteVoluntarioSlice(state).vecino;
export const selectDeleteVoluntarioActive = (state: RootState) =>
  selectDeleteVoluntarioSlice(state).active;
export const selectDeleteVoluntarioErrors = (state: RootState) =>
  selectVoluntariosSlice(state).errors;

/******************** SHOW VOLUNTARIOS **************************************/
export const selectShowVoluntarioSlice = (state: RootState) => state.voluntario;
export const selectShowVoluntario = (state: RootState) =>
  selectShowVoluntarioSlice(state).data;
export const selectShowVoluntarioLoading = (state: RootState) =>
  selectShowVoluntarioSlice(state).loading;
export const selectShowVoluntarioError = (state: RootState) =>
  selectShowVoluntarioSlice(state).errors;

/******************** SHOW DISPONIBILIDAD **************************************/
export const selectVoluntarioDisponibilidadSlice = (state: RootState) =>
  state.voluntarioDisponibilidad;
export const selectVoluntarioDisponibilidad = (state: RootState) =>
  selectVoluntarioDisponibilidadSlice(state).data;

export const selectVoluntarioDisponibilidadErrors = (state: RootState) =>
  selectVoluntarioDisponibilidadSlice(state).errors;

export const selectVoluntarioDisponibilidadLoading = (state: RootState) =>
  selectVoluntarioDisponibilidadSlice(state).loading;

/******************** UPDATE DISPONIBILIDAD *****************************/
export const selectUpdateVoluntarioDisponibilidadSlice = (state: RootState) =>
  state.updateVoluntarioDisponibilidad;

export const selectUpdateVoluntarioDisponibilidadErrors = (state: RootState) =>
  selectUpdateVoluntarioDisponibilidadSlice(state).errors;

export const selectUpdateVoluntarioDisponibilidadLoading = (state: RootState) =>
  selectUpdateVoluntarioDisponibilidadSlice(state).loading;

/******************** SHOW ACUERDO **************************************/
export const selectVoluntarioAcuerdoSlice = (state: RootState) =>
  state.voluntarioAcuerdo;

export const selectVoluntarioAcuerdo = (state: RootState) =>
  selectVoluntarioAcuerdoSlice(state).data;

export const selectVoluntarioAcuerdoErrors = (state: RootState) =>
  selectVoluntarioAcuerdoSlice(state).errors;

export const selectVoluntarioAcuerdoLoading = (state: RootState) =>
  selectVoluntarioAcuerdoSlice(state).loading;

/******************** UPLOAD ACUERDO *****************************/
export const selectUploadVoluntarioAcuerdoSlice = (state: RootState) =>
  state.uploadVoluntarioAcuerdo;

export const selectUploadVoluntarioAcuerdoErrors = (state: RootState) =>
  selectUploadVoluntarioAcuerdoSlice(state).errors;

export const selectUploadVoluntarioAcuerdoLoading = (state: RootState) =>
  selectUploadVoluntarioAcuerdoSlice(state).loading;

/******************** SHOW PERFIL **************************************/
export const selectPerfilVoluntarioSlice = (state: RootState) =>
  state.perfilVoluntario;

export const selectPerfilVoluntario = (state: RootState) =>
  selectPerfilVoluntarioSlice(state).data;

export const selectPerfilVoluntarioErrors = (state: RootState) =>
  selectPerfilVoluntarioSlice(state).errors;

export const selectPerfilVoluntarioLoading = (state: RootState) =>
  selectPerfilVoluntarioSlice(state).loading;

/******************** UPDATE ACUERDO *****************************/
export const selectUpdateVoluntarioAcuerdoSlice = (state: RootState) =>
  state.updateVoluntarioAcuerdo;

export const selectUpdateVoluntarioAcuerdo = (state: RootState) =>
  selectUpdateVoluntarioAcuerdoSlice(state).data;

export const selectUpdateVoluntarioAcuerdoErrors = (state: RootState) =>
  selectUpdateVoluntarioAcuerdoSlice(state).errors;

export const selectUpdateVoluntarioAcuerdoLoading = (state: RootState) =>
  selectUpdateVoluntarioAcuerdoSlice(state).loading;
