import { APIValidationError } from '../../types/api/api';
import { VoluntarioDisponibilidad } from '../../types/voluntarios/VoluntarioDisponibilidad';
import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';

export interface UpdateVoluntarioDisponibilidadDTO {
  id: UUID;
  disponibilidad: VoluntarioDisponibilidad;
}

export const postVoluntarioDisponibilidad = async (
  data: UpdateVoluntarioDisponibilidadDTO,
): Promise<Result<VoluntarioDisponibilidad, APIValidationError>> => {
  return validatedApiCall<VoluntarioDisponibilidad>(
    `/api/voluntarios/disponibilidad/${data.id}`,
    {
      method: 'POST',
      auth: true,
      body: JSON.stringify({ dias: data.disponibilidad }),
    },
  );
};
