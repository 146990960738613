import React, { ReactElement } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { getError, Paths } from '../../../../helpers/inputs/inputHelper';
import useIsRequired from '../../../../hooks/useIsRequired';
import SelectField, { SelectFieldProps } from '../SelectField/SelectField';
import styles from '../SelectField/selectField.module.scss';
interface ValidatedTextSelect<T extends FieldValues> extends SelectFieldProps {
  select: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > & {
    name: Paths<T, 2>;
  };
  errors: FieldErrors<T>;
  schema: any;
  label: string | ReactElement;
  placeholder?: string;
  strict?: boolean;
}

const ValidatedSelect = <T extends FieldValues>({
  errors,
  schema,
  label,
  select,
  children,
  placeholder,
  strict = false,
  ...other
}: ValidatedTextSelect<T>): ReactElement => {
  const fname = select.name as string;
  const error = getError(errors, fname);
  const required = useIsRequired(fname, schema);

  return (
    <SelectField
      children={children}
      select={select}
      error={error}
      placeholder={placeholder}
      strict={strict}
      {...other}
      // We change the label directly intead of passing the required prop
      label={
        label && required ? (
          <>
            {label}
            <span className={styles.asterisk}>{'\u00a0*'}</span>
          </>
        ) : (
          label
        )
      }
    />
  );
};

export default ValidatedSelect;
