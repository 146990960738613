import { getUser as getUsr } from '../../api/users/getUser';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const userModule = createApiModule({
  name: 'user',
  apiMethod: getUsr,
  initialData: null,
  parse: (user) => user,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreUsersUserModuleNoHemosPodidoCargarElLaUsuarioA'),
  }),
  onValidationError: () => ({
    message: t('StoreUsersUserModuleNoHemosPodidoCargarElLaUsuarioA'),
  }),
});

export const {
  start: getUser,
  success: getUserOk,
  error: getUserKo,
  reset: resetUser,
} = userModule.slice.actions;

export default userModule;
