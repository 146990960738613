import { registerPueblo } from '../../api/pueblos/registerPueblo';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const registerPuebloModule = createApiModule({
  name: 'registerPueblo',
  apiMethod: registerPueblo,
  onSuccess: (pueblo) => ({
    message: t('StoreMunicipioCreadoCorrectamente', {
      poblacion: pueblo.poblacion,
    }),
    path: '/pueblos',
  }),
  onError: () => ({
    message: t('StorePueblosRegisterPuebloModuleErrorCreandoElMunicipio'),
  }),
  onValidationError: () => ({
    message: t('StorePueblosRegisterPuebloModuleErrorCreandoElMunicipio'),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerPuebloModule.slice.actions;

export const { sagas } = registerPuebloModule;

export default registerPuebloModule;
