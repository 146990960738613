import { APIValidationError } from '../../types/api/api';
import { PerfilVoluntarioDTO } from './perfilVoluntarioDTO';
import { PerfilVoluntario } from '../../types/voluntarios/PerfilVoluntario';
import { Result, validatedApiCall } from '../api';

export const postPerfilVoluntario = async (
  data: PerfilVoluntarioDTO,
): Promise<Result<PerfilVoluntario, APIValidationError>> => {
  return validatedApiCall<PerfilVoluntario>(`/api/voluntarios/perfil`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
