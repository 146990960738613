import { getPueblos, PueblosDTO } from '../../api/pueblos/getPueblos';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Pueblo } from '../../types/pueblos/pueblo';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

export interface PuebloModule extends GenericState<unknown> {
  data: PueblosDTO;
}

const initialState: PuebloModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Pueblo[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listPuebloModule = createApiModule({
  name: 'listPueblos',
  apiMethod: getPueblos,
  initialState,
  parse: (pueblos) => pueblos,
  onSuccess: () => {},
  onError: () => ({
    message: t('StorePueblosListPuebloModuleErrorListandoLosMunicipios'),
  }),
  onValidationError: () => {},
});

export const {
  start: listPueblos,
  success: listPueblosOk,
  error: listPueblosKo,
  refresh: listPueblosRefresh,
  reset: listPueblosReset,
} = listPuebloModule.slice.actions;

export const { sagas } = listPuebloModule;

export default listPuebloModule;
