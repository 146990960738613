import { Result, validatedApiCall } from '../api';
import { Poblacion } from '../../types/address/address';
import { APIValidationError } from '../../types/api/api';

interface PoblacionDTO {
  data: Poblacion[];
}

export const loadPoblaciones = async (
  codigo_postal: string,
): Promise<Result<PoblacionDTO, APIValidationError>> => {
  return validatedApiCall<PoblacionDTO>(
    `/api/meta/codigos_postales?cp=${codigo_postal}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
