import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';
import './styles/global.scss';
import * as serviceWorker from './serviceWorker';
const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // Add the "!" operator to assert that container is not null

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
