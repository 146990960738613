import { Result, validatedApiCall } from '../api';
import { UpdateEntidadDTO } from '../../types/entidad/Register';
import { APIValidationError } from '../../types/api/api';
import { Entidad } from '../../types/entidad/Entidad';

export const updateEntidad = async (
  data: UpdateEntidadDTO,
): Promise<Result<Entidad, APIValidationError>> => {
  return validatedApiCall<Entidad>(`/api/entidades/${data.id}`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
};
