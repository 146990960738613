import { APIValidationError } from '../../types/api/api';
import { DonacionDestino } from '../../types/donaciones/donacionDestino';
import { Donacion } from '../../types/donaciones/donaciones';
import { DonacionPeriodicidad } from '../../types/donaciones/donacionPeriodicidad';
import { Month } from '../../types/facturacion/month';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { PAGE, SIZE, UUID, Year } from '../../types/standard';
import toQueryParams from '../../utils/toQueryParams';
import { Result, validatedApiCall } from '../api';

export interface GetDonacionesDTO {
  page?: PAGE;
  size?: SIZE;
  tipo?: DonacionPeriodicidad | string;
  pueblo_id?: UUID;
  destino?: DonacionDestino | string;
  month?: Month | string;
  year?: Year | string;
}
export interface DonacionesDTO {
  data: Donacion[];
  links: LinksPage;
  meta: PageInfo;
}

export const getDonaciones = async (
  data: GetDonacionesDTO,
): Promise<Result<DonacionesDTO, APIValidationError>> => {
  return validatedApiCall<DonacionesDTO>(
    `/api/donaciones?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
