import { ReactElement, useState, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import isFieldError from '../../../../helpers/isFieldError';
import styles from './selectField.module.scss';

export type Variant = 'tiny' | 'default' | 'clean';
export interface SelectFieldProps {
  select: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;
  label?: string | ReactElement;
  error?: string | FieldError;
  children: React.ReactNode;
  placeholder?: string;
  strict?: boolean;
  variant?: Variant;
}

const stylePicker = (variant: Variant): string => {
  switch (variant) {
    case 'tiny':
      return styles.isTiny;
    case 'default':
    case 'clean':
      return '';
  }
};

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  error,
  select,
  children,
  placeholder,
  strict = false,
  variant = 'default',
}) => {
  const [hasValue, setHasValue] = useState(
    strict
      ? select.defaultValue === 0 || !!select.defaultValue
      : !!select.defaultValue,
  );

  const checkIfHasValue = useCallback(
    (val: any) => setHasValue(val),
    [setHasValue],
  );

  const id = select.id || select.name;
  const errorMessage: string | JSX.Element = isFieldError(error)
    ? error.message || ''
    : error || '';
  return (
    <div className={`${styles.row}`}>
      <label className={`${styles.label} ${stylePicker(variant)}`} htmlFor={id}>
        {label}
      </label>
      <select
        onChange={(e) => {
          checkIfHasValue(e.target.value);
          select.onChange && select.onChange(e);
        }}
        data-testid={id}
        className={
          variant !== 'clean'
            ? `${styles.selectDropDown} 
        ${hasValue || !placeholder ? styles.hasValue : ''} ${stylePicker(
          variant,
        )}`
            : ''
        }
        id={id}
        {...select}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </select>
      {error && <p className={styles.errorText}>{errorMessage}</p>}
    </div>
  );
};

export default SelectField;
