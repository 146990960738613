import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { ISO8601DateString } from '../../types/standard';
import { Recurso } from '../../types/recursos/Recurso';

export interface ListRecursoParams {
  page?: number;
  size?: number;
  fecha_inicio?: ISO8601DateString;
  fecha_fin?: ISO8601DateString;
}

export interface ListRecursoDTO {
  data: Recurso[];
  links: LinksPage;
  meta: PageInfo;
}

export const listRecurso = async (
  data: ListRecursoParams,
): Promise<Result<ListRecursoDTO, APIValidationError>> => {
  return validatedApiCall<ListRecursoDTO>(
    `/api/recursos?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
