import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { APIValidationError } from '../../types/api/api';
import { Actividad } from '../../types/actividad/Actividad';

export const getActividadById = async (
  id: UUID,
): Promise<Result<Actividad, APIValidationError>> => {
  return validatedApiCall<Actividad>(`/api/actividad/${id}`, {
    method: 'GET',
    auth: true,
  });
};
