import { postRecurso } from '../../api/recursos/postRecurso';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const uploadRecursoModule = createApiModule({
  name: 'uploadRecurso',
  apiMethod: postRecurso,
  parse: (Recurso) => Recurso,
  onSuccess: () => ({
    message: t('StoreUploadRecursoModuleOk'),
  }),
  onError: () => ({
    message: t('StoreUploadRecursoModuleError'),
  }),
  onValidationError: () => ({
    message: t('StoreUploadRecursoModuleError'),
  }),
});

export const {
  start: uploadRecurso,
  success: uploadRecursoOk,
  error: uploadRecursoKo,
} = uploadRecursoModule.slice.actions;

export default uploadRecursoModule;
