import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetupIntentResult } from '@stripe/stripe-js';
import Stripe from 'stripe';
import { APIError } from '../../../types/api/api';

export interface SetupIntentState {
  loading: boolean;
  setup_intent: Stripe.SetupIntent | null;
  errors: APIError | null;
}

const initialState: SetupIntentState = {
  loading: false,
  setup_intent: null,
  errors: null,
};

const setupIntentSlice = createSlice({
  name: 'setupIntent',
  initialState: initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    createSetupIntent(state) {
      state.loading = true;
      state.setup_intent = null;
      state.errors = null;
    },
    createSetupIntentOk(state, action: PayloadAction<Stripe.SetupIntent>) {
      state.loading = false;
      state.setup_intent = action.payload;
    },
    createSetupIntentKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
    addPaymentMethod(state, action: PayloadAction<Stripe.PaymentMethod>) {
      state.loading = true;
      state.errors = null;
    },
    addPaymentMethodOk(state, action: PayloadAction<Stripe.SetupIntent>) {
      state.setup_intent = action.payload;
      state.loading = false;
    },
    addPaymentMethodKo(
      state,
      action: PayloadAction<APIError | SetupIntentResult>,
    ) {
      state.loading = false;
      if (
        'error' in action.payload &&
        action.payload.error &&
        'type' in action.payload.error
      ) {
        const result = action.payload as SetupIntentResult;
        if (result.setupIntent) {
          state.setup_intent = result.setupIntent as Stripe.SetupIntent;
        }
        if (result.error) {
          state.errors = {
            errorType: 'unprocessable',
            message: result.error.message
              ? result.error.message
              : 'Error validando el método de pago',
          };
        }
      } else {
        state.errors = action.payload as APIError;
      }
    },
  },
});

export const {
  reset,
  createSetupIntent,
  createSetupIntentKo,
  createSetupIntentOk,
  addPaymentMethod,
  addPaymentMethodKo,
  addPaymentMethodOk,
} = setupIntentSlice.actions;

export default setupIntentSlice;
