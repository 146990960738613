import { getSocio } from '../../api/vecinos/vecinoSocio';
import { VecinoSocio } from '../../types/socio/socio';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const getVecinoSocioModule = createApiModule({
  name: 'vecinoSocio',
  initialData: {} as VecinoSocio,
  apiMethod: getSocio,
  parse: (data) => data,
  onError: () => ({
    message: t(
      'StoreVecinosGetVecinoSocioModuleErrorRecuperandoSusDatosDeSocioA',
    ),
  }),
  onSuccess: () => {},
});

export const {
  start: getVecinoSocio,
  success: getVecinoSocioOk,
  error: getVecinoSocioErrors,
  refresh: getVecinoSocioRefresh,
} = getVecinoSocioModule.slice.actions;

export default getVecinoSocioModule;
