import { Result, validatedApiCall } from '../api';
import { RegisterPuebloDTO } from '../../types/pueblos/register';
import { APIValidationError } from '../../types/api/api';
import { Pueblo } from '../../types/pueblos/pueblo';

export const registerPueblo = async (
  data: RegisterPuebloDTO,
): Promise<Result<Pueblo, APIValidationError>> => {
  return validatedApiCall<Pueblo>(`/api/pueblos`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
