import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import {
  DNI,
  Phone,
  UUID,
  Address,
  AddressNumber,
  AddressFloor,
  ISO8601DateString,
} from '../../types/standard';
import { User } from '../../types/users/users';
import { Gender } from '../../types/users/gender';
import { Role } from '../../types/auth/roles';

export interface UpdateVecinoDTO {
  id: UUID;
  nombre: string;
  apellido1: string;
  apellido2: string;
  dni: DNI;
  telefono: Phone;
  calle: Address;
  numero: AddressNumber;
  piso: AddressFloor;
  genero: Gender;
  fecha_nacimiento: ISO8601DateString;
  role: Role | '';
  entidad_id: UUID | '';
}

export const updateVecino = async (
  data: UpdateVecinoDTO,
): Promise<Result<User, APIValidationError>> => {
  return validatedApiCall<User>(`/api/vecinos/${data.id}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
};
