import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { loadPoblaciones } from '../../api/address/loadPoblaciones';
import { Await } from '../../types/api/api';
import poblacionesSlice from './poblacionesSlice';
import { isApiError } from '../../api/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* addressSaga(
  action: PayloadAction<string>,
): Generator<any, void, any> {
  try {
    const result = (yield call(loadPoblaciones, action.payload)) as Await<
      ReturnType<typeof loadPoblaciones>
    >;
    switch (result.type) {
      case 'ok':
        yield put(
          poblacionesSlice.actions.loadPoblacionesOk(result.value.data),
        );
        return;
      case 'validation-error':
        yield put(poblacionesSlice.actions.loadPoblacionesKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(poblacionesSlice.actions.loadPoblacionesKo(e));
    }
    throw e;
  }
}

const sagas = [
  takeLatest<PayloadAction<string>>(
    poblacionesSlice.actions.loadPoblaciones.type,
    addressSaga,
  ),
];

export default sagas;
