import { delay, take } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { put, select, takeLatest } from 'redux-saga/effects';
import authSlice from '../auth/authSlice';
import { selectCanReadAlerts, selectIsLoggedIn } from '../auth/selectors';
import alertsModule from './alertsModule';
import newAlertsModule from './newAlertsModule';
import { ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router';

let checkAlerts = false;

function* getAlerts(): Generator<any, void, any> {
  checkAlerts = yield select(selectCanReadAlerts);

  if (checkAlerts) {
    yield put(
      alertsModule.slice.actions.start({
        page: 1,
        size: 10,
      }),
    );
  }
}

/**
 * Refreses the list of alerts every minute.
 *
 * The correct way to do this would be for the API to notify us
 * when there is a change and then refresh, but we are not there
 * yet.
 */
function* getAlertsEveryMinut() {
  while (true && checkAlerts) {
    try {
      if (selectIsLoggedIn(yield select())) {
        yield put(
          newAlertsModule.slice.actions.start({
            page: 1,
            size: 10,
            read: 0,
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
    yield delay(60 * 1000);
  }
}

// retrieve alerts when the page is reloaded as long as the user remains logged in
function* reloadAlerts(): Generator<any, void, any> {
  yield take(ROUTER_ON_LOCATION_CHANGED);
  if (checkAlerts && selectIsLoggedIn(yield select())) {
    yield getAlerts();
    yield getAlertsEveryMinut();
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(authSlice.actions.loginOk.type, getAlerts),
  takeLatest<PayloadAction<never>>(
    authSlice.actions.loginOk.type,
    getAlertsEveryMinut,
  ),

  getAlertsEveryMinut(),
  reloadAlerts(),
];

export default sagas;
