import { postVoluntarioAcuerdo } from '../../api/voluntarios/postVoluntarioAcuerdo';
import createApiModule from '../createApiModule';

const uploadVoluntarioAcuerdoModule = createApiModule({
  name: 'uploadVoluntarioAcuerdo',
  apiMethod: postVoluntarioAcuerdo,
  onSuccess: () => ({
    message: `Acuerdo de voluntario guardado correctamente`,
  }),
  onError: () => ({
    message: 'Error al guardar el acuerdo de voluntario',
  }),
  onValidationError: () => ({
    message: 'Error al guardar el acuerdo de voluntario',
  }),
});

export const {
  start: uploadVoluntarioAcuerdo,
  success: uploadVoluntarioAcuerdoOk,
  error: uploadVoluntarioAcuerdoKo,
} = uploadVoluntarioAcuerdoModule.slice.actions;

export default uploadVoluntarioAcuerdoModule;
