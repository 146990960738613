import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import {
  Address,
  AddressFloor,
  AddressNumber,
  UUID,
} from '../../types/standard';
import { User } from '../../types/users/users';

export interface ChangePuebloDTO {
  user_id: UUID;
  pueblo_id: UUID;
  calle: Address;
  numero: AddressNumber;
  piso: AddressFloor;
}

export const changePueblo = async (
  data: ChangePuebloDTO,
): Promise<Result<User, APIValidationError>> => {
  return validatedApiCall<User>(`/api/vecinos/${data.user_id}/change_pueblo`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
};
