import React from 'react';
import featured from '../../../../assets/login/featured.png';
import logoAlella from '../../../../assets/logo_alella.png';
import logoLaCaixa from '../../../../assets/logo_la_caixa.png';
import logoOxFam from '../../../../assets/logo_oxfam.png';
import logoDiputacioBarcelona from '../../../../assets/logo_diputacio_barcelona.png';
import logoPobles from '../../../../assets/logo_pobles.svg';
import SnackBar from '../../SnackBar/SnackBar';
import styles from './publicLayout.module.scss';
import { Outlet } from 'react-router-dom';

interface PublicLayoutProps {
  variant?: PublicLayoutVariants;
}

export type PublicLayoutVariants = 'valign' | null;

const variantPicker = (variant: PublicLayoutVariants) => {
  switch (variant) {
    case 'valign':
      return styles.valign;
    default:
      return '';
  }
};

const PublicLayout: React.FC<PublicLayoutProps> = ({ variant = null }) => {
  return (
    <div className={`${styles.root} ${variantPicker(variant)}`}>
      <div className={styles.mainImageWrapper}>
        <div className={styles.wrappedImage}>
          <div className={styles.alignCenter}>
            <img src={logoPobles} className={styles.logoPobles} alt="" />
            <img src={featured} alt="" />
          </div>
        </div>
        <div className={styles.footer}>
          <img className={styles.logo} src={logoAlella} alt="Alella" />
          <img className={styles.logo} src={logoLaCaixa} alt="LaCaixa" />
          <img
            className={styles.logo}
            src={logoDiputacioBarcelona}
            alt="Diputació de Barcelona"
          />
          <img className={styles.logo} src={logoOxFam} alt="OxFam" />
        </div>
      </div>
      <main className={`${styles.wrapper}`}>
        <div className={`${styles.mainContent}`}>
          <Outlet />
        </div>
      </main>
      <SnackBar />
    </div>
  );
};

export default PublicLayout;
