import Stripe from 'stripe';
import { APIValidationError } from '../../../types/api/api';
import { Result, validatedApiCall } from '../../api';

export const getPaymentMethods = async (): Promise<
  Result<Stripe.PaymentMethod[], APIValidationError>
> => {
  return validatedApiCall(`/api/stripe/override_restricted/payment_methods`, {
    method: 'GET',
    auth: true,
  });
};

export const detachPaymentMethod = async (
  method: Stripe.PaymentMethod,
): Promise<Result<Stripe.PaymentMethod, APIValidationError>> => {
  return validatedApiCall(
    `/api/stripe/override_restricted/payment_methods/${method.id}/detach`,
    {
      method: 'POST',
      auth: true,
    },
  );
};
