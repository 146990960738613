import { updateValoracion } from '../../api/valoraciones/updateValoracion';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const updateValoracionModule = createApiModule({
  name: 'updateValoracion',
  apiMethod: updateValoracion,
  parse: (data) => data,
  onSuccess: () => {},
  onError: () => ({
    message: t(
      'StoreValoracionesUpdateValoracionModuleSeHaProducidoUnErrorRegistrandoSuValoracion',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreValoracionesUpdateValoracionModuleSeHaProducidoUnErrorRegistrandoSuValoracion',
    ),
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
  reset,
} = updateValoracionModule.slice.actions;

export default updateValoracionModule;
