import { APIValidationError } from '../../types/api/api';
import { VoluntarioDisponibilidad } from '../../types/voluntarios/VoluntarioDisponibilidad';
import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';

export const getVoluntarioDisponibilidad = async (
  id: UUID,
): Promise<Result<VoluntarioDisponibilidad, APIValidationError>> => {
  return validatedApiCall<VoluntarioDisponibilidad>(
    `/api/voluntarios/disponibilidad/${id}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
