import { changeEstadoSolicitud } from '../../api/solicitudes/changeEstadoSolicitud';
import { GenericState } from '../../types/apiModule';
import { Solicitud } from '../../types/solicitud/solicitud';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { resetSolicitud } from './solicitudModule';
import { t } from 'i18next';
export interface ChangeEstadoSolicitudModule extends GenericState<unknown> {
  data: Solicitud;
}

const initialState: ChangeEstadoSolicitudModule = {
  errors: null,
  loading: false,
  data: {} as Solicitud,
};

const changeEstadoSolicitudModule = createApiModule({
  name: 'changeEstadoSolicitud',
  apiMethod: changeEstadoSolicitud,
  initialState,
  onSuccess: (solicitud) => [
    showSnackBar({
      severity: 'success',
      message: t('MESSAGE_SOLICITUD_ESTADO_CAMBIADO'),
      path: '/solicitudes',
    }),
    resetSolicitud(),
  ],
  onError: () => ({
    message: t(
      'StoreSolicitudChangeEstadoSolicitudModuleErrorCambiandoElEstadoDeLaSolicitud',
    ),
  }),
  onValidationError: () => ({
    message: t(
      'StoreSolicitudChangeEstadoSolicitudModuleErrorCambiandoElEstadoDeLaSolicitud',
    ),
  }),
});

export const {
  start: changeEstado,
  success: changeEstadoOk,
  error: changeEstadoKo,
} = changeEstadoSolicitudModule.slice.actions;

export default changeEstadoSolicitudModule;
