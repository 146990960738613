import { StripeSessionRequest } from '../types/stripe';

export function formatAmountForDisplay(
  amount: number,
  currency: string,
): string {
  const numberFormat = new Intl.NumberFormat(['es-ES'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  return numberFormat.format(amount);
}

export function formatStripeAmountForDisplay(
  amount: any,
  currency: string,
): string {
  return formatAmountForDisplay(Number(amount) / 100, currency);
}

export function convertStripeAmountToAmount(amount: any) {
  return Number(amount) / 100;
}

export function formatAmountForStripe(
  amount: number,
  currency: string,
): number {
  const numberFormat = new Intl.NumberFormat(['es-ES'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  return zeroDecimalCurrency ? amount : Math.round(amount * 100);
}

export function defaultCheckoutSessionRequest(): StripeSessionRequest {
  const url =
    window.location.protocol +
    '//' +
    window.location.host +
    `/donacion/result/{CHECKOUT_SESSION_ID}`;
  return {
    submit_type: 'donate',
    payment_method_types: [
      //'alipay',
      'card',
      //'ideal',
      //'fpx',
      //'bacs_debit',
      //'bancontact',
      //'giropay',
      //'p24',
      //'eps',
      //'sofort',
      'sepa_debit',
      //'grabpay',
      //'afterpay_clearpay',
    ],
    line_items: [],
    success_url: url,
    cancel_url: url,
    redirectToCheckout: true,
  };
}
