import { APIValidationError } from '../../types/api/api';
import { VoluntarioAcuerdo } from '../../types/voluntarios/VoluntarioAcuerdo';
import { Result, validatedApiCall } from '../api';
import { fileDTO } from '../../types/file/file';

export const postVoluntarioAcuerdo = async (
  data: fileDTO,
): Promise<Result<VoluntarioAcuerdo, APIValidationError>> => {
  const dataForm = new FormData();
  dataForm.append('acuerdo', data.file, data.file.name);
  return validatedApiCall<VoluntarioAcuerdo>(`/api/voluntarios/acuerdo`, {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  });
};
