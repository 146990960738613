import { getProducts as apiGetProducts } from '../../../api/stripe/bridge/products';
import { GenericState } from '../../../types/apiModule';
import { StripeProducts } from '../../../types/stripe';
import createApiModule from '../../createApiModule';
import { t } from 'i18next';
export interface ProductsModule extends GenericState<unknown> {
  data: StripeProducts;
}

const initialState: ProductsModule = {
  errors: null,
  loading: false,
  data: [],
};

const productsModule = createApiModule({
  name: 'products',
  apiMethod: apiGetProducts,
  initialState,
  parse: (products) => products,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreStripeProductsProductsModuleErrorRecuperandoLosProductos'),
  }),
  onValidationError: () => {},
});

export const {
  start: getProducts,
  success: getProductsOk,
  error: getProductsKo,
  refresh: getProductsRefresh,
} = productsModule.slice.actions;

export const { sagas } = productsModule;

export default productsModule;
