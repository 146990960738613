import { call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PaymentIntentResult } from '@stripe/stripe-js';
import Stripe from 'stripe';
import {
  DonacionUserDTO,
  registerDonacionUser,
} from '../../api/donaciones/registerDonacionUser';
import { Await } from '../../types/api/api';
import { DonacionStatus } from '../../types/donaciones/donacionStatus';
import getStripe from '../../utils/get-stripe';
import registerDonacionSlice from './registerDonacionSlice';
import { isApiError } from '../../api/api';

function* registerDonacion(
  action: PayloadAction<DonacionUserDTO>,
): Generator<any, void, any> {
  try {
    const result = (yield call(registerDonacionUser, action.payload)) as Await<
      ReturnType<typeof registerDonacionUser>
    >;

    let donacion;
    switch (result.type) {
      case 'ok':
        donacion = result.value;
        if (donacion.status === 'requires_action') {
          yield put(
            registerDonacionSlice.actions.registerDonacionMustConfirm(
              result.value,
            ),
          );
          if (donacion.payment_intent) {
            const paymentResult = (yield call(
              confirmPayment,
              donacion.payment_intent,
            )) as PaymentIntentResult;
            if (paymentResult.error) {
              yield put(
                registerDonacionSlice.actions.registerConfirmKo({
                  errorType: 'unknown',
                  message: paymentResult.error.message
                    ? paymentResult.error.message
                    : 'Se ha producido un error durante la confirmación del pago',
                }),
              );
            } else {
              yield put(
                registerDonacionSlice.actions.registerConfirmOk(
                  paymentResult.paymentIntent.status as DonacionStatus,
                ),
              );
            }
          }
          return;
        }
        yield put(
          registerDonacionSlice.actions.registerDonacionOk(result.value),
        );

        return;
      case 'validation-error':
        yield put(
          registerDonacionSlice.actions.registerDonacionKo(result.value),
        );
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(registerDonacionSlice.actions.registerDonacionKo(e));
    }
    throw e;
  }
}

async function confirmPayment(
  paymentIntent: Stripe.PaymentIntent,
): Promise<PaymentIntentResult> {
  const stripe = await getStripe();
  if (stripe && paymentIntent.client_secret) {
    return await stripe.confirmCardPayment(paymentIntent.client_secret);
  }
  return {
    error: {
      type: 'api_connection_error',
      message: 'Stripe no está disponible en estos momentos',
    },
  };
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    registerDonacionSlice.actions.registerDonacion.type,
    registerDonacion,
  ),
];
export default sagas;
