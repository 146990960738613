import { APIValidationError } from '../../types/api/api';
import { Recurso } from '../../types/recursos/Recurso';
import { Result, validatedApiCall } from '../api';
import { fileDTO } from '../../types/file/file';

export const postRecurso = async (
  data: fileDTO,
): Promise<Result<Recurso, APIValidationError>> => {
  const dataForm = new FormData();
  dataForm.append('fichero', data.file, data.file.name);
  return validatedApiCall<Recurso>(`/api/recursos`, {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  });
};
