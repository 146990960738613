import Stripe from 'stripe';
import { APIValidationError } from '../../../types/api/api';
import { AllowedPaymentMethod } from '../../../types/stripe';
import { Result, validatedApiCall } from '../../api';

export interface UpdateSetupIntent {
  setup_intent_id: string;
  data: Object;
}

export const updateSetupIntent = async (
  data: UpdateSetupIntent,
): Promise<Result<Stripe.SetupIntent, APIValidationError>> => {
  return validatedApiCall<Stripe.SetupIntent>(
    `/api/stripe/bridge/setup_intents/${data.setup_intent_id}`,
    {
      method: 'POST',
      auth: false,
      body: JSON.stringify(data.data),
    },
  );
};

export interface ConfirmSetupIntentDTO {
  setup_intent_id: string;
  payment_type: AllowedPaymentMethod;
  return_url?: string;
}

export const confirmSetupIntent = async (
  dto: ConfirmSetupIntentDTO,
): Promise<Result<Stripe.SetupIntent, APIValidationError>> => {
  return validatedApiCall<Stripe.SetupIntent>(
    `/api/stripe/bridge/setup_intents/${dto.setup_intent_id}/confirm`,
    {
      method: 'POST',
      auth: false,
      body: JSON.stringify({
        payment_type: dto.payment_type,
        return_url: dto.return_url,
      }),
    },
  );
};
