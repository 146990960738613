import { registerSesion } from '../../api/sesiones/registerSesion';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const registerSesionModule = createApiModule({
  name: 'registerSesion',
  apiMethod: registerSesion,
  onSuccess: (sesion) => ({
    message: t('MESSAGE_SESION_REGISTRADA'),
    path: '/acompanamiento',
  }),
  onError: () => ({
    message: t(
      'StoreSesionRegisterSesionModuleSeHaProducidoUnErrorRegistrandoLaSesion',
    ),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerSesionModule.slice.actions;

export const { sagas } = registerSesionModule;

export default registerSesionModule;
