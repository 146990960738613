import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocaleES from './locale/es.json';
import LocaleCA from './locale/ca.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      es: LocaleES,
      ca: LocaleCA,
    },
    detection: {
      order: ['sessionStorage', 'navigator'],
      caches: ['sessionStorage'],
    },
  });

export default i18n;
