import { Result, validatedApiCall } from '../api';
import { RegisterEntidadDTO } from '../../types/entidad/Register';
import { APIValidationError } from '../../types/api/api';
import { Entidad } from '../../types/entidad/Entidad';

export const registerEntidad = async (
  data: RegisterEntidadDTO,
): Promise<Result<Entidad, APIValidationError>> => {
  return validatedApiCall<Entidad>(`/api/entidades`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
