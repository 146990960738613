import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import toQueryParams from '../../utils/toQueryParams';
import { UserStates } from '../../types/users/state';

interface VecinoExport {
  content: string;
}
export interface ExportVecinoDTO {
  pueblo?: UUID;
  buscar?: string;
  estado?: UserStates;
}

export const exportVecino = async (
  data: ExportVecinoDTO,
): Promise<Result<VecinoExport, APIValidationError>> => {
  return validatedApiCall<VecinoExport>(
    `/api/export/vecinos?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
