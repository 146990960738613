import { changePassword } from '../../api/forgotPassword/changePassword';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const changePasswordModule = createApiModule({
  name: 'updatePassword',
  apiMethod: changePassword,
  onSuccess: () => ({
    message: t('MESSAGE_CONTRASENA_MODIFICADA'),
    path: '/login',
  }),
  onError: () => ({
    message: t('StoreForgotPasswordChangePasswordModuleElLaUsuarioANoExiste'),
  }),
  onValidationError: () => ({
    message: t('StoreForgotPasswordChangePasswordModuleElLaUsuarioANoExiste'),
  }),
});

export const {
  start: updatePassword,
  success: updatePasswordOk,
  error: updatePasswordKo,
} = changePasswordModule.slice.actions;

export default changePasswordModule;
