import { postPerfilVoluntario } from '../../api/voluntarios/postPerfilVoluntario';
import createApiModule from '../createApiModule';

const postPerfilVoluntarioModule = createApiModule({
  name: 'postPerfilVoluntario',
  apiMethod: postPerfilVoluntario,
  onSuccess: () => ({
    message: `Perfil de voluntario guardado correctamente`,
  }),
  onError: () => ({
    message: 'Error al guardar el perfil de voluntario',
  }),
  onValidationError: () => ({
    message: 'Error al guardar el perfil de voluntario',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = postPerfilVoluntarioModule.slice.actions;

export default postPerfilVoluntarioModule;
