import { RootState } from '../store';

/******************** VECINOS **************************************/
export const selectVecinosSlice = (state: RootState) => state.vecinos;
export const selectVecino = (state: RootState) =>
  selectVecinosSlice(state).data.data;
export const selectVecinoLoading = (state: RootState) =>
  selectVecinosSlice(state).loading;
export const selectVecinoPageInfo = (state: RootState) =>
  selectVecinosSlice(state).data.meta;
/******************** CHANGE PUEBLO VECINO **************************************/

export const selectChangePuebloVecinoUser = (state: RootState) =>
  state.changePuebloVecino.user;

export const selectChangePuebloVecinoActive = (state: RootState) =>
  state.changePuebloVecino.active;

export const selectChangePuebloVecinoLoading = (state: RootState) =>
  state.changePuebloVecino.loading;
export const selectChangePuebloVecinoErrors = (state: RootState) =>
  state.changePuebloVecino.errors;

/******************** UPDATE VECINO *****************************/
export const selectUpdateVecinoSlice = (state: RootState) => state.updateVecino;

export const selectUpdateVecinoErrors = (state: RootState) =>
  selectUpdateVecinoSlice(state).errors;

export const selectUpdateVecinoLoading = (state: RootState) =>
  selectUpdateVecinoSlice(state).loading;

/******************** DELETE VECINO *****************************/
export const selectDeleteVecino = (state: RootState) =>
  state.deleteVecino.vecino;

export const selectDeleteVecinoActive = (state: RootState) =>
  state.deleteVecino.active;

export const selectDeleteVecinoLoading = (state: RootState) =>
  state.deleteVecino.loading;

/******************** Socio **************************************/
export const selectVecinoSocio = (state: RootState) => state.vecinoSocio.data;

export const selectVecinoSocioLoading = (state: RootState) =>
  state.vecinoSocio.loading;

export const selectVecinoSocioErrors = (state: RootState) =>
  state.vecinoSocio.errors;

/******************** Register socio *****************************/
export const selectRegisterSocioSelectedPuebloId = (state: RootState) =>
  state.registerSocio.registerData?.pueblo_id;

export const selectRegisterVecinoSocioData = (state: RootState) =>
  state.registerSocio.registerData;

export const selectRegisterVecinoSocioConfirmActive = (state: RootState) =>
  state.registerSocio.confirmActive;

export const selectRegisterSocioLoading = (state: RootState) =>
  state.registerSocio.loading;

/******************** Delete socio *******************************/
export const selectDeleteVecinoSocioSocio = (state: RootState) =>
  state.deleteSocio.data;
export const selectDeleteVecinoSocioLoading = (state: RootState) =>
  state.deleteSocio.loading;
export const selectDeleteVecinoSocioConfirmActive = (state: RootState) =>
  state.deleteSocio.active;

/******************** CANCEL ASSOCIATE SUBSCRIPTION **************************************/

export const selectCancelAssociateSubscriptionUser = (state: RootState) =>
  state.cancelAssociateSubscription.user;

export const selectCancelAssociateSubscriptionActive = (state: RootState) =>
  state.cancelAssociateSubscription.active;

export const selectCancelAssociateSubscriptionLoading = (state: RootState) =>
  state.cancelAssociateSubscription.loading;
export const selectCancelAssociateSubscriptionErrors = (state: RootState) =>
  state.cancelAssociateSubscription.errors;
