import Stripe from 'stripe';
import { APIValidationError } from '../../types/api/api';
import { DonacionDestino } from '../../types/donaciones/donacionDestino';
import { Donacion } from '../../types/donaciones/donaciones';
import { DonacionPeriodicidad } from '../../types/donaciones/donacionPeriodicidad';
import { DonacionStatus } from '../../types/donaciones/donacionStatus';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export interface DonacionUserDTO {
  periodicidad: DonacionPeriodicidad;
  destino: DonacionDestino;
  pueblo_id: UUID | null;
  importe: number;
}

export type MakeDonacionUserDTO = Donacion & {
  status: DonacionStatus;
  payment_intent?: Stripe.PaymentIntent;
  subscription?: Stripe.Subscription;
};

export const registerDonacionUser = async (
  data: DonacionUserDTO,
): Promise<Result<MakeDonacionUserDTO, APIValidationError>> => {
  return validatedApiCall<MakeDonacionUserDTO>(`/api/donaciones`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
