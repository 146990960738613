import { registerUser } from '../../api/users/registerUser';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const registerUserModule = createApiModule({
  name: 'registerUser',
  apiMethod: registerUser,
  onSuccess: (user) => ({
    message: t('StoreUsuarioCreadoCorrectamente', {
      nombre: user.nombre,
      apellido1: user.apellido1,
      apellido2: user.apellido2,
    }),
    path: '/usuarios',
  }),
  onError: () => ({
    message: t('StoreUsersRegisterUserModuleErrorCreandoElLaUsuarioA'),
  }),
  onValidationError: () => ({
    message: t('StoreUsersRegisterUserModuleErrorCreandoElLaUsuarioA'),
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerUserModule.slice.actions;

export default registerUserModule;
