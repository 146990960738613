import { APIValidationError } from '../../types/api/api';
import { ExternalUser } from '../../types/users/users';
import { Result, validatedApiCall } from '../api';

export const registerExternalDonator = async (
  data: ExternalUser,
): Promise<Result<ExternalUser, APIValidationError>> => {
  return validatedApiCall<ExternalUser>(`/api/users/external/donator`, {
    method: 'PUT',
    auth: false,
    body: JSON.stringify(data),
  });
};
