import { APIValidationError } from '../../types/api/api';
import {
  Donacion,
  DonacionExternaDTO,
} from '../../types/donaciones/donaciones';
import { Result, validatedApiCall } from '../api';

export const donacionExterna = async (
  data: DonacionExternaDTO,
): Promise<Result<Donacion, APIValidationError>> => {
  return validatedApiCall<Donacion>(`/api/donaciones/externa`, {
    method: 'POST',
    auth: false,
    body: JSON.stringify(data),
  });
};
