import { lazy } from 'react';
import PublicRoute from '../../components/common/Router/PublicRoute/PublicRoute';

const PoliticaPrivacidadCA = lazy(() => import('./PoliticaPrivacidadCA'));
const AvisoLegalCA = lazy(() => import('./AvisoLegalCA'));

const PoliticaPrivacidadES = lazy(() => import('./PoliticaPrivacidadES'));
const AvisoLegalES = lazy(() => import('./AvisoLegalES'));

const LoginRoutes = [
  {
    path: 'politica-privacidad',
    element: <PublicRoute component={PoliticaPrivacidadES} />,
  },
  {
    path: 'aviso-legal',
    element: <PublicRoute component={AvisoLegalES} />,
  },
  {
    path: 'politica-privadesa',
    element: <PublicRoute component={PoliticaPrivacidadCA} />,
  },
  {
    path: 'avis-legal',
    element: <PublicRoute component={AvisoLegalCA} />,
  },
];

export default LoginRoutes;
