import { getPuebloById } from '../../api/pueblos/getPuebloById';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const puebloModule = createApiModule({
  name: 'pueblo',
  apiMethod: getPuebloById,
  initialData: null,
  parse: (pueblo) => pueblo,
  onSuccess: () => {},
  onError: () => ({
    message: t('StorePueblosPuebloModuleNoHemosPodidoCargarElPueblo'),
    path: '/pueblos',
  }),
  onValidationError: () => ({
    message: t('StorePueblosPuebloModuleNoHemosPodidoCargarElPueblo'),
    path: '/pueblos',
  }),
});

export const {
  start: getPueblo,
  success: getPuebloOk,
  error: getPuebloKo,
  reset: resetPueblo,
} = puebloModule.slice.actions;

export default puebloModule;
