import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Voluntario } from '../../types/voluntarios/Voluntario';
import { UUID } from '../../types/standard';

export const showVoluntario = async (
  id: UUID | undefined,
): Promise<Result<Voluntario, APIValidationError>> => {
  return validatedApiCall<Voluntario>(`/api/voluntarios/${id}`, {
    method: 'GET',
    auth: true,
  });
};
