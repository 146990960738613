import { all } from '@redux-saga/core/effects';
import actividadModule from './actividad/actividadModule';
import deleteActividadModule from './actividad/deleteActividadModule';
import listActividadModule from './actividad/listActividadModule';
import postActividadModule from './actividad/postActividadModule';
import updateActividadModule from './actividad/updateActividadModule';
import getAlertsModule from './alerts/alertsModule';
import deleteAlertsModule from './alerts/deleteAlertsModule';
import newAlertsModule from './alerts/newAlertsModule';
import readAlertsModule from './alerts/readAlertsModule';
import alertsSagas from './alerts/sagas';
import authSagas from './auth/sagas';
import downloadCertificadoModule from './certificado/downloadCertificadoModule';
import listComunicacionModule from './comunicacion/ListComunicacionModule';
import registerComunicacionModule from './comunicacion/RegisterComunicacionModule';
import deleteDonacionModule from './donaciones/deleteDonacionModule';
import listDonacionesModule from './donaciones/lisDonacionesModule';
import registerDonacionSagas from './donaciones/sagas';
import donacionExternaModule from './donacionExterna/donacionExternaModule';
import listDonacionesOwnModule from './donacionOwn/listDonacionesOwnModule';
import deleteEntidadModule from './entidades/deleteEntidadModule';
import entidadModule from './entidades/entidadModule';
import listEntidadModule from './entidades/listEntidadModule';
import registerEntidadModule from './entidades/registerEntidadModule';
import setPrincipalEntidadModule from './entidades/setPrincipalEntidadModule';
import updateEntidadModule from './entidades/updateEntidadModule';
import externalDonatorModule from './externalDonators/externalDonatorModule';
import facturacionModule from './facturacion/facturacionModule';
import changePasswordModule from './forgotPassword/changePasswordModule';
import sendPasswordModule from './forgotPassword/sendPasswordModule';
import kpisModule from './kpis/kpisModule';
import poblacionSagas from './poblacion/sagas';
import deletePuebloModule from './pueblos/deletePuebloModule';
import listPuebloModule from './pueblos/listPuebloModule';
import puebloModule from './pueblos/puebloModule';
import registerPuebloModule from './pueblos/registerPuebloModule';
import updatePuebloModule from './pueblos/updatePuebloModule';
import listSesionModule from './sesion/listSesionModule';
import registerSesionModule from './sesion/registerSesionModule';
import changeEstadoSolicitudModule from './solicitud/changeEstadoSolicitudModule';
import listSolicitudModule from './solicitud/listSolicitudModule';
import registerSolicitudModule from './solicitud/registerSolicitudModule';
import sendSolicitudModule from './solicitud/sendSolicitudModule';
import solicitudModule from './solicitud/solicitudModule';
import checkoutSessionSagas from './stripe/checkoutSession/sagas';
import listPaymentMethodsModule from './stripe/customer/listPaymentMethods';
import customerSliceSagas from './stripe/customer/sagas';
import paymentMethodSagas from './stripe/paymentMethod/sagas';
import productsModule from './stripe/products/productsModule';
import setupIntentSagas from './stripe/setupIntent/sagas';
import deleteUserModule from './users/deleteUserModule';
import exportUserModule from './users/exportUserModule';
import listUserModule from './users/listUserModule';
import registerUserModule from './users/registerUserModule';
import updateUserModule from './users/updateUserModule';
import userModule from './users/userModule';
import bajaVecinoModule from './users/vecinos/bajaVecinoModule';
import bajaVecinoRestrictionsModule from './users/vecinos/bajaVecinoRestrictionsModule';
import registerVecinoSagas from './users/vecinos/sagas';
import getValoracionModule from './valoraciones/getValoracionModule';
import registerValoracionModule from './valoraciones/registerValoracionModule';
import updateValoracionModule from './valoraciones/updateValoracionModule';
import changePuebloModule from './vecinos/changePuebloModule';
import deleteVecinoModule from './vecinos/deleteVecinoModule';
import deleteVecinoSocioModule from './vecinos/deleteVecinoSocioModule';
import exportVecinosModule from './vecinos/exportVecinosModule';
import getVecinoSocioModule from './vecinos/getVecinoSocioModule';
import listVecinoModule from './vecinos/listVecinosModule';
import vecinoSagas from './vecinos/sagas';
import updateVecinoModule from './vecinos/updateVecinoModule';
import updateDatosPersonalesModule from './perfil/updateDatosPersonalesModule';
import deleteVoluntarioModule from './voluntarios/deleteVoluntarioModule';
import listVoluntariosModule from './voluntarios/ListVoluntariosModule';
import voluntarioBajaModule from './voluntarios/voluntarioBajaModule';
import voluntarioEstadoModule from './voluntarios/voluntarioEstadoModule';
import voluntarioDisponibilidadModule from './voluntarios/voluntarioDisponibilidadModule';
import updateVoluntarioDisponibilidadModule from './voluntarios/updateVoluntarioDisponibilidadModule';
import uploadVoluntarioAcuerdoModule from './voluntarios/uploadVoluntarioAcuerdoModule';
import perfilVoluntarioModule from './voluntarios/perfilVoluntarioModule';
import postPerfilVoluntarioModule from './voluntarios/postPerfilVoluntarioModule';
import showVoluntarioModule from './voluntarios/showVoluntarioModule';
import cancelAssociateSubscriptionModule from './vecinos/cancelAssociateSubscription';
import voluntarioAcuerdoModule from './voluntarios/voluntarioAcuerdoModule';
import updateVoluntarioAcuerdoModule from './voluntarios/updateVoluntarioAcuerdoModule';
import listRecursoModule from './recursos/listRecursoModule';
import uploadRecursoModule from './recursos/uploadRecursoModule';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...registerUserModule.sagas,
    ...listUserModule.sagas,
    ...getAlertsModule.sagas,
    ...readAlertsModule.sagas,
    ...alertsSagas,
    ...newAlertsModule.sagas,
    ...registerPuebloModule.sagas,
    ...poblacionSagas,
    ...listPuebloModule.sagas,
    ...puebloModule.sagas,
    ...updatePuebloModule.sagas,
    ...deletePuebloModule.sagas,
    ...externalDonatorModule.sagas,
    ...checkoutSessionSagas,
    ...donacionExternaModule.sagas,
    ...sendPasswordModule.sagas,
    ...changePasswordModule.sagas,
    ...registerEntidadModule.sagas,
    ...listEntidadModule.sagas,
    ...deleteEntidadModule.sagas,
    ...entidadModule.sagas,
    ...updateEntidadModule.sagas,
    ...setPrincipalEntidadModule.sagas,
    ...exportUserModule.sagas,
    ...registerVecinoSagas,
    ...productsModule.sagas,
    ...deleteUserModule.sagas,
    ...listDonacionesModule.sagas,
    ...listVecinoModule.sagas,
    ...exportVecinosModule.sagas,
    ...updateUserModule.sagas,
    ...userModule.sagas,
    ...listPaymentMethodsModule.sagas,
    ...setupIntentSagas,
    ...paymentMethodSagas,
    ...customerSliceSagas,
    ...deleteDonacionModule.sagas,
    ...registerDonacionSagas,
    ...deleteVecinoModule.sagas,
    ...updateVecinoModule.sagas,
    ...updateDatosPersonalesModule.sagas,
    ...facturacionModule.sagas,
    ...changePuebloModule.sagas,
    ...getVecinoSocioModule.sagas,
    ...vecinoSagas,
    ...deleteVecinoSocioModule.sagas,
    ...listDonacionesOwnModule.sagas,
    ...listSolicitudModule.sagas,
    ...registerSolicitudModule.sagas,
    ...downloadCertificadoModule.sagas,
    ...sendSolicitudModule.sagas,
    ...solicitudModule.sagas,
    ...listVoluntariosModule.sagas,
    ...changeEstadoSolicitudModule.sagas,
    ...registerValoracionModule.sagas,
    ...getValoracionModule.sagas,
    ...bajaVecinoRestrictionsModule.sagas,
    ...bajaVecinoModule.sagas,
    ...updateValoracionModule.sagas,
    ...registerSesionModule.sagas,
    ...listSesionModule.sagas,
    ...voluntarioEstadoModule.sagas,
    ...voluntarioDisponibilidadModule.sagas,
    ...updateVoluntarioDisponibilidadModule.sagas,
    ...voluntarioAcuerdoModule.sagas,
    ...uploadVoluntarioAcuerdoModule.sagas,
    ...perfilVoluntarioModule.sagas,
    ...postPerfilVoluntarioModule.sagas,
    ...voluntarioBajaModule.sagas,
    ...registerComunicacionModule.sagas,
    ...listComunicacionModule.sagas,
    ...deleteAlertsModule.sagas,
    ...postActividadModule.sagas,
    ...listActividadModule.sagas,
    ...deleteActividadModule.sagas,
    ...actividadModule.sagas,
    ...updateActividadModule.sagas,
    ...deleteVoluntarioModule.sagas,
    ...kpisModule.sagas,
    ...showVoluntarioModule.sagas,
    ...cancelAssociateSubscriptionModule.sagas,
    ...voluntarioAcuerdoModule.sagas,
    ...updateVoluntarioAcuerdoModule.sagas,
    ...listRecursoModule.sagas,
    ...uploadRecursoModule.sagas,
  ]);
}
