import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export const deleteVoluntario = async (
  id: UUID | undefined,
): Promise<Result<boolean, APIValidationError>> => {
  return validatedApiCall<boolean>(`/api/voluntarios/${id}`, {
    method: 'DELETE',
    auth: true,
  });
};
