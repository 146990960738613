import { getActividadById } from '../../api/actividad/getActividadById';
import createApiModule from '../createApiModule';
import { t } from 'i18next';

const actividadModule = createApiModule({
  name: 'actividad',
  apiMethod: getActividadById,
  initialData: null,
  parse: (entidad) => entidad,
  onSuccess: () => {},
  onError: () => ({
    message: t('StoreActividadActividadModuleNoHemosPodidoCargarLaEntidad'),
    path: '/entidades',
  }),
  onValidationError: () => ({
    message: t('StoreActividadActividadModuleNoHemosPodidoCargarLaEntidad'),
    path: '/entidades',
  }),
});

export const {
  start: getActividad,
  success: getActividadOk,
  error: getActividadKo,
  reset: resetActividad,
} = actividadModule.slice.actions;

export default actividadModule;
