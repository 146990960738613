import React from 'react';
import { connect } from 'react-redux';
import {
  hide,
  del as deleteActividad,
} from '../../../store/actividad/deleteActividadModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';
import { Actividad } from '../../../types/actividad/Actividad';
import {
  selectDeleteActividad,
  selectDeleteActividadActive,
  selectDeleteActividadLoading,
} from '../../../store/actividad/selectors';

interface DeleteActividadProps {
  actividad: Actividad | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deleteActividad: (id: UUID) => void;
}

const DeleteActividad: React.FC<DeleteActividadProps> = ({
  actividad,
  active,
  loading,
  closeModal,
  deleteActividad,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {actividad && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar actividad
            </h1>
            <p className={styles.description}>
              ¿Estás seguro de querer eliminar la actividad?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>Nombre: </span>
                  {actividad.nombre}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                variant="positive"
                onClick={() => {
                  deleteActividad(actividad.id);
                  closeModal();
                }}
                data-testid="submit_button"
              >
                Eliminar
              </Button>
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteActividad = connect(
  (state: RootState) => ({
    actividad: selectDeleteActividad(state),
    active: selectDeleteActividadActive(state),
    loading: selectDeleteActividadLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deleteActividad: (payload: UUID) => dispatch(deleteActividad(payload)),
    closeModal: () => dispatch(hide()),
  }),
)(DeleteActividad);

export default renderWhen(
  ConnectedDeleteActividad,
  selectDeleteActividadActive,
);
