import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import toQueryParams from '../../utils/toQueryParams';

interface UserExport {
  content: string;
}
export interface ExportUserDTO {
  pueblo?: UUID;
  buscar?: string;
  role?: string;
}

export const exportUsers = async (
  data: ExportUserDTO,
): Promise<Result<UserExport, APIValidationError>> => {
  return validatedApiCall<UserExport>(
    `/api/export/users?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    },
  );
};
