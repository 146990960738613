import { FieldError } from 'react-hook-form';

export default function isFieldError(
  err: string | FieldError | undefined,
): err is FieldError {
  if (!err) return false;
  if (typeof err === 'string') return false;
  if (typeof err === 'object' && typeof err.message === 'string') return true;
  return false;
}
